import { Component, OnInit, ChangeDetectorRef, AfterViewInit, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { UserService, AppointmentService, LunchService, ServiceService, LocationService, CategoryService, ReasonService, ClosedService, TaxService, HashService, LoaderService } from '@services';
import { ModalService, MongoService, SocketService } from 'wacom';
import { Router, ActivatedRoute } from '@angular/router';
import { StaffByIdPipe } from '@pipes'
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import * as  moment  from 'moment-timezone'
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	animations: [
		trigger('flyInOut', [
			state('in', style({ transform: 'translateX(0)' })),
			transition('void => *', [
				style({
					transform: 'translateY(-20px)',
					opacity: 0,
				}),
				animate(300)
			]),
			transition('* => void', [
				animate(300, style({
					opacity: 0,
					// height: '50px'
					transform: 'translateY(-20px)',
				}))
			])
		]),
		trigger('tabInOut', [
			state('in', style({ transform: 'translateX(0)' })),
			transition('void => *', [
				style({
					transform: 'translateX(20px)',

					opacity: 0,
				}),
				animate(300)
			]),
			transition('* => void', [
				animate(300, style({
					opacity: 0,
					// height: '50px'
					transform: 'translateX(20px)',
				}))
			])
		])
	]
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
	public use_moment:any = moment;
	public calendarScrollLock:any;
	public calendar = this.router.url.includes('/calendar');
	public filterShow: any;
	public config: object = {
		delay: 300,
		delta: 30
	};
	public freeday = {
		dateoff: [               
		new Date("1/1/2023"),
		new Date("7/4/2023"),
		new Date("5/22/2023"),
		new Date("7/1/2023"),
		new Date("9/4/2023"),
		new Date("10/9/2023"),
		new Date("11/11/2023"),
		new Date("12/25/2023"),
	]
	  };

	  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {

		const day = cellDate.getDay();
		const full = cellDate.getDay();
		const selleb = cellDate.getTime();

		if (view == 'month') {
			return (day == 0 || full == 6 ||this.freeday.dateoff.find(x=>x.getTime()==selleb)) ? 'highlight-date' : "";
		}
	
		return "";
	}
	public popupOpened;
	scrollStrategy: ScrollStrategy;
	/* Common Management */
		constructor(public modal: ModalService,
			public router: Router,
			public mongo: MongoService,
			public cdr: ChangeDetectorRef,
			public aps: AppointmentService,
			public loc: LocationService,
			public ls: LunchService,
			public ss: ServiceService,
			public us: UserService,
			public cat: CategoryService,
			public rs: ReasonService,
			public cs: ClosedService,
			private stp: StaffByIdPipe,
			public modalW: ModalService,
			public ts: TaxService,
			private act_route: ActivatedRoute,
			public hash: HashService,
			private socket: SocketService,
			private readonly sso: ScrollStrategyOptions,
			private datePipe: DatePipe,
			private loader: LoaderService,
			private eref: ElementRef) {
			this.mongo.on('location', ()=>{
				if(this.loc._locations.primary && this.loc._locations.primary.length){
					this.location = this.loc._locations.primary[0]._id;
				}
			});
			const update = (arr)=>{
				this.getAppointmentAndLunch(this.model.getValue());
				this.ls.loaded(() => {
					this.aps.loaded(() => {
						this.isOfficeCalc = {};
						this.isClosedDate = {};
						this.isVacation = {};
						this.isWorking = {};
						this.isHoliday = {};
						this.aps.refresh();
					});
				});
			};
			// this.socket.on('refreshAppointment', update);
			this.us.headerTitle = this.calendar && 'Calendar' || 'My Schedule';
			this.us.headerSubtitle = '';
			const now = new Date();
			now.setHours(0);
			now.setMinutes(0);
			now.setSeconds(0);
			for (let i = 0; i < 1440 / this.step; i++){
				this.times.push(new Date(now.getTime()+(i*this.step*60000)));
			}
			this.scrollStrategy = this.sso.close();
		}
	/* Lunch Management */

		edit_lunch(profile){
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.aps.pre_set(profile, this.model.getValue());
			this.modal.show({
				component: 'lunch',
				profile: JSON.parse(JSON.stringify(profile))
			});
		}
	/* Appointment Management */
		edit_appointment(profile){
			this.aps.pre_set(profile, this.model.getValue());
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'appointment',
				profile: JSON.parse(JSON.stringify(profile))
			});
		}
		create_appointment(time, user:any=false){
			let appointment:any = {status: 'New'};
			this.aps.pre_set(appointment, this.model.getValue());
			appointment.start = this.datePipe.transform(new Date(time), 'HH:mm');
			if(user) {
				if(!!user.role) {
					appointment.user = user._id;
					this.aps.setLocation(appointment);
				} else {
					appointment.client = user._id;
				}
			}

			this.edit_appointment(appointment);
		}
	/* Calendar Management */
		public show_dates = true;
			// prev(){
			// 	this.model.singleDate.jsDate = new Date(this.model.singleDate.jsDate.getTime()-86400000);
			// 	this.model.singleDate.epoc = this.model.singleDate.jsDate.getTime() / 1000;
			// 	this.model.singleDate.date = {
			// 		year: this.model.singleDate.jsDate.getFullYear(),
			// 		month: this.model.singleDate.jsDate.getMonth()+1,
			// 		day: this.model.singleDate.jsDate.getDate()
			// 	};
			// 	this.show_dates = false;
			// 	setTimeout(()=>{
			// 		this.show_dates = true;
			// 	}, 1);
			// }
			// next(){
			// 	this.model.singleDate.jsDate = new Date(this.model.singleDate.jsDate.getTime()+86400000);
			// 	this.model.singleDate.epoc = this.model.singleDate.jsDate.getTime() / 1000;
			// 	this.model.singleDate.date = {
			// 		year: this.model.singleDate.jsDate.getFullYear(),
			// 		month: this.model.singleDate.jsDate.getMonth()+1,
			// 		day: this.model.singleDate.jsDate.getDate()
			// 	};
			// 	this.show_dates = false;
			// 	setTimeout(()=>{
			// 		this.show_dates = true;
			// 	}, 1);
			// }
	/* Remove Below Later */
	public calWeekShow: any;
	public calWeekShowi: any;
	/* staff */
	public staff_info:any;
	public appointHistory:any;
	set_staff_info(staff){
		let prev_staff = this.staff_info;
		setTimeout(()=>{
			if(prev_staff==staff) this.staff_info=null;
			else this.staff_info=staff;
		});
	}
	/* drag staff */
	drop(event: CdkDragDrop<object[]>) {
		let users = event.container.data;
		users.splice(event.previousIndex, 1);
		users.splice(event.currentIndex, 0, event.item.data);
		users.forEach((doc: any, i) => {
			doc.order = i;
			this.us.save(doc);
		});
		this.aps.refresh();
	}
	/* filters */
	public location:any;

	/* lunch */
	public profile_lunch:any = {};
	/* appointment */
	public profile_appointment:any = {};
	/* timeframe */
	public step = 60;
	public times = [];
	/* date*/
	set_picker_view(dp, dp2){
		console.log(dp, dp2);
	}
	public model = new BehaviorSubject <any>({
		isRange: false,
		singleDate: {
		  	jsDate: new Date(),
		  	formatted: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()
		}
	});

	render(){
		// setTimeout(()=>{
		// 	this.cdr.markForCheck();
		// });
	}
	public selectStatuses = [
		{ value: 1, label: 'Canceled' },
		{ value: 2, label: 'New' },
		{ value: 3, label: 'Completed' },
		{ value: 4, label: 'Confirmed' },
	];
	ngOnInit(): void {		
		this.model.subscribe(value => {
			this.getAppointmentAndLunch(this.model.getValue());
		});
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.calendar-container'));
		
		this.act_route.queryParamMap.subscribe((params:any) => {
			if({ ...params.params }.reschedule){
				this.router.navigate([],{ queryParams: { modal: 'open' },queryParamsHandling: 'merge' });
				this.modalW.show({component: 'reschedule', _id: { ...params.params }.reschedule})
			}
		});
	}
	ngDoCheck(){
		if( this.act_route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	ngOnDestroy() {		
		this.model.unsubscribe();
	}
	getAppointmentAndLunch(model) {
		if(this.calendar) {
			this.aps.get([model.singleDate.formatted]);
			this.ls.get([model.singleDate.formatted]);
		} else {
			var d = new Date(model.singleDate.formatted);
			var days = [];
			for (var i = 0; i <= 6; i++) {
				days.push(this.datePipe.transform(new Date(d), 'M/d/yyyy'));
				d.setDate(d.getDate() + 1);
			}
			this.mongo.on('user', () => {
				this.aps.get(days, this.us._id);
				this.ls.get(days, this.us._id);
			});
		}
	}
	/* drag */
	timeTo(time){
		return Number(time.split(':')[0])*60 + Number(time.split(':')[1]);
	}
	update(doc, opts, i, cb:any=resp=>{}){
		var top = Math.floor( (doc.top + (Math.floor(opts.y/20) * 20)) /20)*20;
		if(top<0) top=0;
		let duration = this.timeTo(doc.end) - this.timeTo(doc.start);
		let minutes = top/20*15;
		var start = (Math.floor(minutes/60)<10&&'0'||'')+Math.floor(minutes/60)+':'+(Math.floor(minutes%60)<10&&'0'||'')+Math.floor(minutes%60);
		minutes += duration;
		var end = (Math.floor(minutes/60)<10&&'0'||'')+Math.floor(minutes/60)+':'+(Math.floor(minutes%60)<10&&'0'||'')+Math.floor(minutes%60);

		// get width of column, why +100?
		opts.x += 100;
		let steps = opts.x/200;
		let staffs = this.stp.transform(this.us.allowed_appointments, this.selected_staff, this.selected_category, this.location, this.aps.now)||[];
		if(typeof i == 'number'){
			while(steps<0){
				if(i) i--;
				steps++;
			}
			while(steps>1){
				if(staffs.length-1>i) i++;
				steps--;
			}
			var user = staffs[i]._id;
		}

		if(!this.calendar){
			steps = Math.floor(steps);
			if(steps<0 && steps>-1){
				steps--;
			}
			var day: any = {};
			day.singleDate = {};
			
			day.singleDate.jsDate = new Date(new Date(doc.day.singleDate.jsDate).getTime()+(steps*86400000));
			day.singleDate.epoc = new Date(doc.day.singleDate.jsDate).getTime() / 1000;
			day.singleDate.formatted = (new Date(doc.day.singleDate.jsDate).getMonth()+1)+'/'+new Date(doc.day.singleDate.jsDate).getDate()+'/'+new Date(doc.day.singleDate.jsDate).getFullYear();
			day.singleDate.date = {
				year: new Date(doc.day.singleDate.jsDate).getFullYear(),
				month: new Date(doc.day.singleDate.jsDate).getMonth()+1,
				day: new Date(doc.day.singleDate.jsDate).getDate()
			};
		}		

		let old = {
			_id: doc._id,
			user: doc.user,
			duration: doc.duration,
			start: doc.start,
			end: doc.end,
			top: doc.top,
			day: doc.day
		};

		doc.user = user;
		doc.duration = duration;
		doc.start = start;
		doc.end = end;
		doc.top = top;
		if (day) doc.day = day;
		this.aps.refresh();
		cb(old);
	}
	update_appointment(doc, opts, i:any=null){
		this.add_row = 0;
		var start = doc.start;
		var day = doc.day.singleDate.formatted;
		this.update(doc, opts, i, (old) => {
			this.aps._appointments[doc._id] = doc;
			if(old.user != doc.user || old.day.singleDate.formatted != doc.day.singleDate.formatted) {				
				let index = this.aps._appointments.calendar[old.user+old.day.singleDate.formatted].findIndex((a: any) => a._id == doc._id);
				this.aps._appointments.calendar[old.user+old.day.singleDate.formatted].splice(index, 1);
				if(!this.aps._appointments.calendar[doc.user+doc.day.singleDate.formatted]?.length) this.aps._appointments.calendar[doc.user+doc.day.singleDate.formatted] = [];
				this.aps._appointments.calendar[doc.user+doc.day.singleDate.formatted].push(doc);
			}
			
			this.aps.save(doc, (appointment) => {
				if (start != doc.start || day != doc.day.singleDate.formatted) {
					this.aps.reschedule(doc);
				}
			}, () => {		
				let user = doc.user;	
				let day = doc.day;	
				this.aps._appointments[doc._id].user = old.user;
				this.aps._appointments[doc._id].duration = old.duration;
				this.aps._appointments[doc._id].start = old.start;
				this.aps._appointments[doc._id].end = old.end;
				this.aps._appointments[doc._id].top = old.top;
				this.aps._appointments[doc._id].day = old.day;

				if(old.user != user || old.day.singleDate.formatted != day.singleDate.formatted) {		
					let index = this.aps._appointments.calendar[user+day.singleDate.formatted].findIndex((a: any) => a._id == doc._id);
					this.aps._appointments.calendar[user+day.singleDate.formatted].splice(index, 1);
					if(!this.aps._appointments.calendar[old.user+old.day.singleDate.formatted]?.length) this.aps._appointments.calendar[old.user+old.day.singleDate.formatted] = [];
					this.aps._appointments.calendar[old.user+old.day.singleDate.formatted].push(this.aps._appointments[doc._id]);
					this.aps.refresh();
				}
			});
		});
	}
	update_lunch(doc, opts, i){
		this.add_row = 0;
		this.update(doc, opts, i);
		this.ls.save(doc);
	}
	public row:any;
	private add_row = 0;
	prepare_add_row(opts){
		let from_row = Math.floor((opts.clientY-241)/20)+1;
		this.add_row = from_row - this.row;
		if(this.add_row<0) this.add_row=0;
	}
	card_hover(top, height){
		this.row = Math.floor(top/20)+1;
	}
	row_selected(row, subrow, user:any=false){
		this.row = row*4 + subrow - this.add_row;
	}
	//filters
	public selected_staff: any = [];
	public selected_category: any = [];
	public change = false;
	isDisabled() {
		/*
		return true
		*/
	}
	private isOfficeCalc:any = {};
	private isClosedDate: any = {};
	private isVacation: any = {};
	private isWorking: any = {};
	private isHoliday: any = {};
	isOffice(week, min) {
		let disable = true;
		if(this.location && typeof this.isOfficeCalc[this.location + week + min] == 'boolean') return this.isOfficeCalc[this.location + week + min];
		if(this.location && this.loc._locations[this.location].data.business_hours && this.loc._locations[this.location].data.business_hours[week]&& this.loc._locations[this.location].data.business_hours[week].length) {
			for (let i = 0; i < this.loc._locations[this.location].data.business_hours[week].length; i++){
				let minTime = Number(min.replace(':', '.'));
				let locStart = Number(this.loc._locations[this.location].data.business_hours[week][i].from.replace(':', '.'));
				let locFinish = Number(this.loc._locations[this.location].data.business_hours[week][i].to.replace(':', '.'));
				if(minTime < locFinish && minTime >= locStart) {
					disable = false
				}
			}
			this.isOfficeCalc[this.location + week + min] = disable;
		}
		return disable;
	}
	isClosed() {
		let disable = false;

		if(typeof this.isClosedDate[this.model.getValue().singleDate.formatted + this.location] == 'boolean') return this.isClosedDate[this.model.getValue().singleDate.formatted + this.location];

		for (let i = this.cs.closeds.length-1; i >= 0; i--){
			for (let j = 0; j < this.cs.closeds[i].locations.length; j++) {
				if(
					this.cs.closeds[i].locations[j] == this.location && 
					(
						(
							!this.cs.closeds[i].holiday &&
							new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(this.model.getValue().singleDate?.formatted) && 
							new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(this.model.getValue().singleDate?.formatted)
						) ||
						(
							this.cs.closeds[i].holiday &&
							this.cs.isHoliday(new Date(this.model.getValue().singleDate?.formatted), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
						)
					)
				) {
					disable = true;
					this.isHoliday[this.model.getValue().singleDate.formatted+this.location] = {
						type: this.cs.closeds[i]?.holiday ? 'Holiday' : 'Closed',
						name: this.cs.closeds[i].name,
					}
				}
			}
		}

		this.isClosedDate[this.model.getValue().singleDate.formatted+this.location] = disable;
		return disable
	}
	get holiday() {
		return this.isHoliday[this.model.getValue().singleDate.formatted + this.location];
	}
	isVacationStaff(staff, week, date) {
		if (!staff) return false;
		if(typeof this.isVacation[week + staff._id] == 'boolean') return this.isVacation[week + staff._id];
		if(staff.data.working_hours?.default?.[week]?.length && !staff.data.working_hours?.[date]?.vacation) {
			return false
		} else {
			this.isVacation[week + staff._id] = true;
			return true
		}
	}
	isWorkingStaff(staff, time, date, week) {
		let disable = true;
		if(!staff) return false;
		if(typeof this.isWorking[time + date + staff._id] == 'boolean') return this.isWorking[time + date + staff._id];
		if(staff.data?.working_hours && time) {
			if(staff.data.working_hours[date]?.hours?.length) {
				for (let i = 0; i < staff.data.working_hours[date].hours.length; i++){
					let minTime = Number(time.replace(':', '.'));
					let locStart = Number((staff.data.working_hours[date].hours[i].from||'0').replace(':', '.'));
					let locFinish = Number((staff.data.working_hours[date].hours[i].to||'0').replace(':', '.'));
					if(minTime < locFinish && minTime >= locStart) {
						disable = false
					}
				}
				this.isWorking[time + date + staff._id] = disable;
			} else if(staff.data.working_hours?.default?.[week]?.length) {
				for (let i = 0; i < staff.data.working_hours.default[week].length; i++){
					let minTime = Number(time.replace(':', '.'));
					let locStart = Number((staff.data.working_hours.default[week][i].from||'0').replace(':', '.'));
					let locFinish = Number((staff.data.working_hours.default[week][i].to||'0').replace(':', '.'));
					if(minTime < locFinish && minTime >= locStart) {
						disable = false
					}
				}
				this.isWorking[time + date + staff._id] = disable;
			}
		}

		return disable;
	}
	editWorkingDays(user) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modalW.show({component: 'workingDays', selected_user: JSON.parse(JSON.stringify(user))});
	}
	editWorkingHours(user) {
		console.error('editWorkingHours');
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modalW.show({component: 'workingHours', selected_user: JSON.parse(JSON.stringify(user)), exit: ()=> {
			this.isWorking = {};
		}});
	}

	public isSingleClick = true;

	popup(appointment, i?){
		this.isSingleClick = true;
		this.scrollStrategy = this.sso.close();
		setTimeout(()=>{
			if(this.isSingleClick){
				appointment.selected = !appointment.selected;
				if(appointment.selected) this.popupOpened = i;
				appointment.showPopup = false
			}
		},250)
	}
	open(appointment){
		this.isSingleClick = false;
		appointment.selected = false;
		this.popupOpened = null;
		this.edit_appointment(appointment);
	}
	weekView() {
		this.router.navigate(['/staff']);
		setTimeout(()=> {
			this.hash.set('tab', 'working-hours');
		})
	}
	nextDay(){
		let date = new Date(new Date(this.model.getValue().singleDate.jsDate).getTime()+86400000);
		this.model.next({
			isRange: false,
			singleDate: {
				jsDate: date,
				formatted: (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear()
			}
		});
		this.render();
	}
	previousDay() {
		let date = new Date(new Date(this.model.getValue().singleDate.jsDate).getTime()-86400000);
		this.model.next({
			isRange: false,
			singleDate: {
				jsDate: date,
				formatted: (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear()
			}
		});
		this.render();
	}
	editStaff(user) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'staff', staff: JSON.parse(JSON.stringify(user))})
	}
	public allDay = false;
	top(top) {
		const row_height_px = 80;
		if (this.allDay) {
			return top;
		} else {
			var day = this.datePipe.transform(new Date(this.model.getValue().singleDate.jsDate), 'EEEE');
			var closed = false;
			if (this.location) {
				if(typeof this.isClosedDate[this.model.getValue().singleDate.formatted + this.location] == 'boolean') closed = this.isClosedDate[this.model.getValue().singleDate.formatted + this.location];
			} if (!closed && this.location && this.loc._locations[this.location].data.business_hours && this.loc._locations[this.location].data.business_hours[day]&& this.loc._locations[this.location].data.business_hours[day].length) {
				for (let i = 0; i < this.loc._locations[this.location].data.business_hours[day].length; i++){
					let from = Number(this.loc._locations[this.location].data.business_hours[day][i].from.split(':')[0]);
					return top - row_height_px * from;
				}
			} else {
				return top - row_height_px * 8;
			}
		}
	}
	public now = new Date().getTime();
	refreshHours(){
		this.now = new Date().getTime();
	}
	public vw;
	ngAfterViewInit() {
		this.mongo.on('user location service category reason closed', () => {
			this.ls.loaded(() => {
				this.aps.loaded(() => {
					this.loader.remove();
				});
			});
		});
		this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	}
	filterClose(event) {
		let element = event.target;
		while (element) {
			if (element.classList.contains('filter-sm')) {
				return true;
			}
			element = element.parentElement;
		}
		this.filterShow = false;
	}
	public openPicker = false;
}
