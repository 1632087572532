import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'appointmentsFilter'
})
export class AppointmentsFilterPipe implements PipeTransform {
	transform(appointments, services=[], date, now, statuses=[], staff=[], direction='desc') {
		if(!appointments) return [];
		appointments = appointments.slice();
		if (direction) {
			appointments.sort((doc1, doc2) => {
				var date1 = new Date( doc1.day.singleDate.jsDate ).setHours(doc1.start.split(':')[0], doc1.start.split(':')[1]);
				var date2 = new Date( doc2.day.singleDate.jsDate ).setHours(doc2.start.split(':')[0], doc2.start.split(':')[1]);
				if (date1 < date2 ) return (direction=='desc') ? 1 : -1;
				if ( date1 > date2 ) return (direction=='desc') ? -1 : 1;
				return 0;
			});
		}
		if(!services.length && !statuses.length &&!staff.length && (typeof date == 'undefined'||!date)) return appointments;
		if(services.length) {
			for (let i = appointments.length-1; i >= 0; i--){
				let bool = '0';
				for (let j = services.length-1; j >= 0; j--){
				    if(appointments[i].service == services[j]) {
				    	bool = '1';
				    }
				}
				if(bool == '0') appointments.splice(i, 1);
			}
	    }
		if(statuses.length) {
			for (let i = appointments.length-1; i >= 0; i--){
				let bool = '0';
				for (let j = statuses.length-1; j >= 0; j--){
				    if(appointments[i].status == statuses[j]) {
				    	bool = '1';
				    }
				}
				if(bool == '0') appointments.splice(i, 1);
			}
		}
		if(staff.length) {
			for (let i = appointments.length-1; i >= 0; i--){
				let bool = '0';
				for (let j = staff.length-1; j >= 0; j--){
				    if(appointments[i].user == staff[j]) {
				    	bool = '1';
				    }
				}
				if(bool == '0') appointments.splice(i, 1);
			}
		}
	    if((typeof date != 'undefined'&&date)) {
		    for (let i = appointments.length-1; i >= 0; i--){
				let bool = '0';
				if(date == appointments[i].day.singleDate.formatted) {
					bool = '1';
				}
				if(bool == '0') appointments.splice(i, 1);
		    }
	    }
		return appointments;
	}
}
