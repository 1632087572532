<div class="dashboard dashboard-sales">
	<div class="dashboard-card" style="grid-area: chart-revenue">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Revenue
			</div>
		</div>
		<div class="dashboard-card__content">
			<div class="chart-container">
				<div class="chart">
					<ngx-charts-bar-vertical
						[scheme]="{domain: ['#6AC4B3']}"
						[results]="dashboard.revenueBarChart"
						[xAxis]="true"
						[yAxis]="true"
						[legend]="false"
						[roundDomains]="true"
						[roundEdges]="true"
						[xAxisTickFormatting]="xAxisRevenue"
						[yAxisTickFormatting]="yAxisRevenue"
						[yAxisTicks]="(!dashboard.distribution.total || dashboard.distribution.total == 0) ? [0, 500, 1000] : ''"
						[yScaleMax]="(!dashboard.distribution.total || dashboard.distribution.total == 0) ? 1000 : ''"
						[yScaleMin]="(!dashboard.distribution.total || dashboard.distribution.total == 0) ? 0 : ''"
					>
						<ng-template #tooltipTemplate let-model="model">
							<div class="tooltip">
								<div class="tooltip__title">{{chartTooltipTitleFormatting(model?.name)}}</div>
								<div class="tooltip__value">{{model?.value | currency}}</div>
							</div>
						</ng-template>
					</ngx-charts-bar-vertical>
				</div>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: distribution">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Distribution
			</div>
		</div>
		<div class="dashboard-card__content">
			<donat-chart [total]="dashboard.distribution.total" [items]="dashboard.distribution.items" [legendPosition]="windowWidth <= 1440 && windowWidth >= 768 ? 'left' : 'top'"></donat-chart>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: checks">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Checks
			</div>
		</div>
		<div class="dashboard-card__content checks">
			<app-table [columns]="checksColumns" [rows]="cs.checks">
				<ng-template sort cell="clientName" let-element>
					<div class="client">
						<div class="client__img">
							<picture [user]="us._users[element?.client?._id]"></picture>
						</div>
						<div class="client__name">
							{{element?.clientName}}
						</div>
					</div>
				</ng-template>
				<ng-template sort cell="timestamp" let-element>
					{{element?.timestamp | date:'short'}}
				</ng-template>
				<ng-template sort cell="total" let-element>
					{{element?.total | currency}}
				</ng-template>
				<ng-template sort cell="tips" let-element>
					{{element?.tips | currency}}
				</ng-template>
				<ng-template sort cell="tax" let-element>
					{{element?.tax | currency}}
				</ng-template>
				<ng-template sort cell="type" let-element>
					{{element?.type}}
				</ng-template>
			</app-table>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: services">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Top 10 Services
			</div>
		</div>
		<div class="dashboard-card__content services">
			<app-table [columns]="servicesColumns" [rows]="dashboard.top10_services" [pagination]="false">
				<ng-template cell="minPrice" let-element>
					{{element?.minPrice | currency}}
					{{element?.maxPrice && element?.minPrice != element?.maxPrice ? ' - ' + (element?.maxPrice | currency) : ''}}
				</ng-template>
				<ng-template cell="revenue" let-element>
					{{element?.revenue | currency}}
				</ng-template>
			</app-table>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: products">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Top 10 Products
			</div>
		</div>
		<div class="dashboard-card__content products">
			<app-table [columns]="productsColumns" [rows]="dashboard.top10_products" [pagination]="false">
				<ng-template cell="minPrice" let-element>
					{{element?.minPrice | currency}}
					{{element?.maxPrice && element?.minPrice != element?.maxPrice ? ' - ' + (element?.maxPrice | currency) : ''}}
				</ng-template>
				<ng-template cell="revenue" let-element>
					{{element?.revenue | currency}}
				</ng-template>
			</app-table>
		</div>
	</div>
</div>
