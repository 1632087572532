<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">Reschedule</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-datepicker col-2 _arrow" [class.req]="submitted&&!day">
				<span class="waw-datepicker__title">Select day*</span>
				<datepicker [date]="day" (valueChange)="day=$event" [required]="submitted&&!day"></datepicker>
				<div *ngIf="submitted&&!day" class="req-text">Day is required</div>
			</div>
			<div class="col-2">
				<timepicker 
					label="Start time*" 
					requiredText="Time is required"
					[required]="submitted&&!start" 
					[value]="start" 
					(valueChange)="start = $event" 
				></timepicker>
			</div>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close(); router.navigate(['/calendar'])">Cancel</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Save</button>
		</div>
	</form>
</div>
