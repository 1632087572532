import { Component, OnInit } from '@angular/core';
import { ModalService, AlertService } from 'wacom';
import { UserService, AppointmentService, ServiceService, NoteService, LoaderService } from '@services';
import { DatePipe } from '@angular/common';
import { toHTML } from 'ngx-editor';
@Component({
	selector: 'note',
	templateUrl: './note.component.html',
	styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit{
	public close:any;
	public noteEditor: any;
	public note: any = {};
	public client = true;
	public prefill = false;
	public submitted: boolean = false;
	public characterLimit: string = null;

	constructor(
		public modal: ModalService, 
		public us: UserService, 
		public ap: AppointmentService,
		public ss: ServiceService,
		public nt: NoteService,
		public datePipe: DatePipe,
		public alert: AlertService,
		private loader: LoaderService
	) {
		let date = !this.prefill && new Date() || new Date(this.note.date.singleDate.formatted);
		this.note.date = {
			singleDate: {
				jsDate: date,
				epoc: date.getTime(),
				date: {
					year: date.getFullYear(),
					month: date.getMonth()+1,
					day: date.getDate()
				},
				formatted: (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear()
			}
		}
	}
	ngOnInit(): void {
		if (this.note.description) this.noteEditor = JSON.parse(this.note);
		this.getAppointments();
	}
	create() {
		this.submitted = true;
		if (this.noteEditor) this.note.description = JSON.stringify(this.noteEditor);
		if( !this.note.date || !this.note.client || !this.note.description || toHTML(this.noteEditor) === '<p></p>' ) {
			this.note.description = '';
			return this.alert.error({
				text: "Please fill required fields",
			});
		}
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.nt.create(this.note, () => {
			this.loader.remove();
			this.close();
		});
	}
	remove() {
		this.getAppointments();
		delete this.note.appointment;
	}
	getAppointments() {
		if(this.note.client && !this.prefill) this.ap.getNote(this.note.client, this.note.date.singleDate.formatted);
		else if(this.prefill && this.note.appointment) this.ap.appointments = [this.ap.doc(this.note.appointment)];
		else this.ap.appointments.length = 0;
	}
	noteChange(note) {
		this.noteEditor = note;
		if (this.noteEditor) this.note.description = JSON.stringify(this.noteEditor);
	}
	formatTime(date, time) {
		const d = new Date(date.singleDate.formatted);
		d.setHours(time.split(':')[0], time.split(':')[1]);
		return this.datePipe.transform(d, 'h:mm a');
	}
}
