import { Component, OnInit } from '@angular/core';
import { AppointmentService, LoaderService, UserService } from '@services';
import { Router } from '@angular/router';
import { AlertService } from 'wacom';
@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss']
})
export class RescheduleComponent implements OnInit {
	public _id;
	public close;
	public appointment:any = {};
	public day:string;
	public start:string;
	public submitted = false;
	constructor(public us: UserService, public aps: AppointmentService, public router: Router, private loader: LoaderService, public alert: AlertService) { }
	ngOnInit(): void {
		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.router.navigate([], {
			queryParams: {
			  	reschedule: null,
			  	modal: 'open',
			},
			queryParamsHandling: 'merge'
		});
		
		this.appointment = this.aps.doc(this._id);
		let waitAppointment = setInterval(()=> {
			if(this.appointment._id) {
				this.loader.remove();
				clearInterval(waitAppointment);
				this.start = this.appointment.start;
				this.day = this.appointment.day;
			}
		}, 10);
	}
	save() {
		this.submitted = true;

		if(!this.day||!this.start) return this.alert.error({
			text: "Please fill required fields",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));
		
		this.appointment.previous_date = this.appointment.day;
		this.appointment.previous_time = this.appointment.start;
		this.appointment.day = this.day;
		this.appointment.start = this.start;
		
		this.aps.reschedule(this.appointment, () => {
			this.loader.remove();
			this.submitted = false;
			this.close();
			this.router.navigate(['/calendar']);
		}, () => {
			this.loader.remove();
			this.submitted = false;
		});
	}
}
