import { Injectable } from '@angular/core';
import { MongoService, AlertService, HttpService, ModalService } from 'wacom';
import { LocationService } from './location.service';
import { UserService } from './user.service';
import { PlatformService } from './platform.service'
import { Router } from '@angular/router';
import { ClosedService } from './closed.service';
import { ServiceService } from './service.service';
import { BehaviorSubject } from 'rxjs';
import * as  moment  from 'moment-timezone'

@Injectable({
	providedIn: 'root'
})
export class AppointmentService {
	public appointments: any = [];
	public upcoming: any = [];
	public past: any = [];
	public past_ondate:any = {};
	public past_arr: any = [];
	public all_by_client:any = [];
	public _appointments: any = {
		calendar: {},
	};
	public count: number = 0;
	private loadedSubject = new BehaviorSubject <boolean>(false);
	timeToPixel(time){
		return time / 3600000 * 80;
	}
	timeToRange(time){
		time = time / 60000;
		let h = Math.floor(time / 60);
		let m = Math.floor(time % 60);
		return (h<10&&'0'||'')+h+':'+(m<10&&'0'||'')+m;
	}
	rangeToTime(start, end){
		if(!start||!end) return 0;
		start = start.split(':');
		start[0] = Number(start[0]);
		start[1] = Number(start[1]);
		end = end.split(':');
		end[0] = Number(end[0]);
		end[1] = Number(end[1]);
		
		if(end[1]<start[1]){
			end[0]--;
			end[1]+=60;
		}
		if(end[0] < start[0]){
			end[0] += 24;
		}
		return ( (end[0]-start[0])*60 + end[1]-start[1] ) * 60000;
	}
	private replace = {
		client: (val, cb, doc)=>{
			if(doc.clients && doc.clients.length)  val = doc.clients[0];
			cb(val);
		},
		clients: (val, cb, doc)=>{
			if(!val) val = [doc.client];
			cb(val);
		},
		end: (val, cb, doc)=>{
			if(doc.duration && doc.start){
				cb(this.timeToRange(this.rangeToTime('00:00', doc.start)+(doc.duration*60000)));
			}else cb(val);
		},
		// day: (val, cb, doc)=>{
		// 	if(!val){
		// 		val = {
		// 			dateRange: false,
		// 			isRange: false,
		// 			singleDate: {
		// 				date: {year: 2020, month: 8, day: 17},
		// 				epoc: 1597611600,
		// 				formatted: "8/17/2020",
		// 				jsDate: "2020-08-16T21:00:00.000Z"
		// 			}
		// 		};
		// 	}
		// 	// val.singleDate.jsDate = new Date(val.singleDate.jsDate);
		// 	// if(doc.start&&doc.end) val.singleDate.jsDate.setHours(Number(doc.start.split(':')[0]), Number(doc.start.split(':')[1]));
		// 	cb(val);
		// },
		top: (val, cb, doc)=>{
			cb(this.timeToPixel(this.rangeToTime('00:00', doc.start)));
		},
		height: (val, cb, doc)=>{
			cb(this.timeToPixel(this.rangeToTime(doc.start, doc.end)));
		},
		orig_top: (val, cb, doc)=>{
			cb(doc.top);
		},
		orig_height: (val, cb, doc)=>{
			cb(doc.height);
		}
	};
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	constructor(
		private alert: AlertService, 
		private mongo: MongoService, 
		private us: UserService, 
		private loc: LocationService, 
		public http: HttpService, 
		public modal: ModalService, 
		private ps: PlatformService, 
		private router: Router,
		private cs: ClosedService,
		private ss: ServiceService
		) { 
			this.mongo.config('appointment', {
				replace: this.replace,
				groups: {
					calendar: {
						field: function(doc, cb){
							if(doc.day){
								cb(doc.client+doc.day.singleDate.formatted);
								cb(doc.user+doc.day.singleDate.formatted);
							}
						}
					},
				},
			});
			
		}

		get(dates_formatted: string[], user = null, cb: any = () => {}) {
		this.loadedSubject.next(false);
		this.appointments.length = 0;
		this._appointments.calendar = {};

		this.http.get('/api/appointment/get?dates='+dates_formatted+(user?'&user='+user:''), (resp: any) => {		
			if(!resp) {
				this.loadedSubject.next(true);
				return cb(false);
			}
			
			const appointments = [...this.appointments];
			for (let i = 0; i < appointments.length; i++) {
				this.mongo.remove('appointment', appointments[i]);
			}
			const { arr, obj } = this.mongo.set('appointment', resp);

			this.appointments = arr;
			this._appointments = obj;				

			this.loadedSubject.next(true);
			
			if(typeof cb == 'function') cb(resp);
		});
	}
	getServiceHistoryPagination(config, search: string = '', date = null, services, sort_type, cb:any=event=>{}) {
		this.loadedSubject.next(false);		
		const skip = (config.page - 1) * config.perPage;
		const params = { 
			skip: skip, 
			limit: config.perPage,
			search: search,
			date: date ? date : '',
			services: services ? services : [],
			sortField: sort_type.title ? sort_type.title : '',
			sortOrder: sort_type.direction ? sort_type.direction : ''
		};

		this.http.post('/api/appointment/pagination', params, (resp: any) => {			
			if(resp != false) {
				this.count = resp.count;
				const appointments = [...this.past_arr];
				for (let i = 0; i < appointments.length; i++) {
					this.mongo.remove('appointment', appointments[i]);
				}
				const { arr, obj } = this.mongo.set('appointment', resp.arr);
				
				this.past_arr = arr;
				
				this.now = Date.now();
				this.loadedSubject.next(true);
				if(typeof cb === 'function') cb(true);
			}
		});
	}
	getAllForClient(user: any, cb:any=event=>{}) {
		this.http.get('/api/appointment/getclient?client='+user, resp => {
			const appointments = [...this.all_by_client];
			for (let i = 0; i < appointments.length; i++) {
				this.mongo.remove('appointment', appointments[i]);
			}
			const { arr, obj } = this.mongo.set('appointment', resp);
				
			this.all_by_client = arr;
			this._appointments = obj;
			this.past = arr.filter((doc) => {
					if(typeof doc.day.singleDate.jsDate != 'object'){
						doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
					}
					return doc.day.singleDate.jsDate.getTime() < Date.now();
				});
			this.upcoming = arr.filter((doc) => {
					if(typeof doc.day.singleDate.jsDate != 'object'){
						doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
					}
					return doc.day.singleDate.jsDate.getTime() >= Date.now();
				});
			this.upcoming.sort(this.mongo.sortAscId());

			this.refresh();
			cb();
		});
	}
	getNote(user: any, date: string, cb:any=event=>{}) {
		this.appointments.length = 0;

		this.http.get('/api/appointment/getnote?date='+date+'&client='+user, (resp: any) => {		
			if(!resp) {
				return cb(false);
			}

			console.log(resp);
			
			
			this.appointments = resp;
			
			if(typeof cb == 'function') cb(resp);
		});
	}
	doc(appointmentId){
		if(!this._appointments[appointmentId]){
			this._appointments[appointmentId] = this.mongo.fetch('appointment', {
				query: {
					_id: appointmentId
				}
			});
		}
		return this._appointments[appointmentId];
	}
	create(appointment, cb:any=resp=>{}, show_alert=true, reject:any=resp=>{}) {
		if(appointment._id) return this.save(appointment, cb);
		this.timeConflictCheck(appointment).then(resp => {
			if (resp) {
				appointment.timezone = moment.tz.zone(this.loc._locations[appointment.location].timezone)?.utcOffset(new Date(appointment.day.singleDate.jsDate).getTime());
				this.replace.end(appointment.end, (end)=>{
					appointment.end = end;
				}, appointment);
				appointment.changedStatusManually = (appointment.status == 'Canceled' || appointment.status == 'Confirmed');
				appointment.price = this.ss._services[appointment.service]?.price;
				this.mongo.create('appointment', appointment, (result)=>{
					this.refresh();
					cb(result);
					this.http.post('/api/appointment/send_confirmation', result, resp => {
						if(resp && show_alert) this.alert.show({text: 'Confirmation letter was sent to the customer'});
					});	
					if (show_alert) this.alert.show({
						text: 'Appointment has been created.'
					});
				}, { allow_multiple: true });
			} else {
				reject();
			}
		});
	}
	update(appointment, cb:any=resp=>{}) {
		this.mongo.afterWhile(appointment, ()=> {
			this.save(appointment, cb);
		});
	}
	save(appointment, cb:any=resp=>{}, reject:any=resp=>{}) {
		let ask = false;
		
		this.timeConflictCheck(appointment).then(resp => {
			if (resp) {
				this.replace.end(appointment.end, end=>{
					appointment.end = end;
				}, appointment);
				this.replace.top(appointment.top, top=>{
					this._appointments[appointment._id].top = top;
					appointment.top = top;
				}, appointment);
				this.replace.height(appointment.height, height=>{
					this._appointments[appointment._id].height = height;
					appointment.height = height;
				}, appointment);
				if(appointment.clients && appointment.clients.length)  appointment.client = appointment.clients[0];
				if(appointment.top != appointment.orig_top || appointment.height != appointment.orig_height){
					/*
					this.alert.show({
						component: 'appointment',
						undo: ()=>{
							appointment.top = appointment.orig_top;
							appointment.height = appointment.orig_height;
						}
					});
					*/
				}
				appointment.changedStatusManually = (appointment.status == 'Canceled' || appointment.status == 'Confirmed')
				appointment.price = this.ss._services[appointment.service]?.price;
				this.mongo.update('appointment', appointment, {
					replace: this.replace
				}, ()=> {
					cb()
					this.refresh();
				});
			} else reject();
        });
	}
	delete(appointment, cb:any=resp=>{}, cd_delete:any=resp=>{}) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete appointment',
			body: `Are you sure you want to delete appointment`,
			checkbox: appointment.repeat,
			on_add: (delete_repeat)=> {
				cd_delete();
				if(delete_repeat) {
					this.http.post('/api/appointment/delete_repeatable', appointment, resp => {
						cb();
						this.alert.show({
							text: 'Appointments has been deleted.'
						});
						this.refresh();
					});	
				} else {
					this.mongo.delete('appointment', appointment, ()=> {
						cb();
						this.alert.show({
							text: 'Appointment has been deleted.'
						});
						this.refresh();
					});
				}
				appointment.selected = false; 
				appointment.showPopup = false
			}
		})
		
	}
	setLocation(profile) {
		profile.location = undefined;
		profile.service = undefined;
		if(this.us._users[profile.user].location) {
			const locations = this.us._users[profile.user].location.map((l) => {
				const location = this.loc._locations[l];
				outerLoop: for (let i = this.cs.closeds.length-1; i >= 0; i--){
					for (let j = 0; j < this.cs.closeds[i].locations.length; j++) {
						if(
							this.cs.closeds[i].locations[j] == l && 
							(
								(
									!this.cs.closeds[i].holiday &&
									new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(profile.day?.singleDate?.formatted) && 
									new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(profile.day?.singleDate?.formatted)
								) ||
								(
									this.cs.closeds[i].holiday &&
									this.cs.isHoliday(new Date(profile.day?.singleDate?.formatted), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
								)
							)
						) {
							location.closed = true;
							break outerLoop;
						} else {
							location.closed = false;
						}
					}
				}
				return location;
			});

			for (let location of locations) {
				if(!location.closed) {
					if( !profile.location ) profile.location = location._id;
					if( location._id == this.loc.primary._id ) {
						profile.location = location._id;
						break;
					}
				}
			}
		}
	}
	confirm(appointment) {
		this.http.post('/api/appointment/send_confirmation', appointment, resp => {
			if(resp) this.alert.show({text: 'Confirmation letter was sent to the customer'});
			this.refresh.bind(this)
		});	
	}
	mark(appointment) {
		appointment.history.push({
			user: this.us._id,
			status: 'arrived'
		});
		this.mongo.update('appointment', appointment, {
			name: 'arrived'
		}, updated=>{
			this.alert.show({text: "This client has been marked Arrived"});
			this.refresh();
		});
	}
	cancel(appointment) {
		appointment.history.push({
			user: this.us._id,
			status: 'canceled'
		});
		this.mongo.update('appointment', appointment, {
			name: 'cancel'
		}, updated=>{
			this.alert.show({text: "You've canceled the appointment"});
			this.refresh.bind(this);
		});
	}
	reschedule(appointment, cb:any=resp=>{}, reject:any=resp=>{}) {
		this.timeConflictCheck(appointment).then(resp => {
			if (resp) {
				this.replace.end(appointment.end, end=>{
					appointment.end = end;
				}, appointment);
				this.mongo.update('appointment', appointment, {
					name: 'reschedule'
				}, updated=>{
					cb();
					this.alert.show({text: "Appointment successfully rescheduled"});
					this.refresh();
				});
			} else reject();
		});
	}
	rebook(appointment, repeat, edit=true, cb:any=resp=>{}) {
		if(repeat.enable){
			if(edit) {
				this.http.post('/api/appointment/rebook', {appointment: appointment, repeat: repeat, date: Date.now()}, resp => {
					this.rebookAppointments(appointment, repeat, edit, cb)
				});
			} else {
				this.rebookAppointments(appointment, repeat, edit, cb)
			}
		}
	}
	rebookAppointments(appointment, repeat, edit, cb:any=resp=>{}) {
		let new_appointment = JSON.parse(JSON.stringify(appointment))
		new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.jsDate);
		if(!new_appointment.parent) new_appointment.parent = new_appointment._id;
		new_appointment.status = 'New';
		new_appointment.sent = false;
		delete new_appointment._id;
		if(repeat.kind.name == 'Daily'){
			for (let i = 0; i < repeat.count; i++){
				new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.jsDate.getTime()+86400000);
				new_appointment.day.singleDate.epoc = new_appointment.day.singleDate.jsDate.getTime();
				new_appointment.day.singleDate.date = {
					year: new_appointment.day.singleDate.jsDate.getFullYear(),
					month: new_appointment.day.singleDate.jsDate.getMonth()+1,
					day: new_appointment.day.singleDate.jsDate.getDate()
				};
				new_appointment.day.singleDate.formatted = (new_appointment.day.singleDate.jsDate.getMonth()+1)+'/'+new_appointment.day.singleDate.jsDate.getDate()+'/'+new_appointment.day.singleDate.jsDate.getFullYear()
				this.create(new_appointment, ()=> {
					if(i == repeat.count - 1) cb();
				}, false);
			}
		}else if(repeat.kind.name == 'Weekly'){
			for (let i = 0; i < repeat.count; i++){
				new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.jsDate.getTime()+604800000);
				new_appointment.day.singleDate.epoc = new_appointment.day.singleDate.jsDate.getTime();
				new_appointment.day.singleDate.date = {
					year: new_appointment.day.singleDate.jsDate.getFullYear(),
					month: new_appointment.day.singleDate.jsDate.getMonth()+1,
					day: new_appointment.day.singleDate.jsDate.getDate()
				};
				new_appointment.day.singleDate.formatted = (new_appointment.day.singleDate.jsDate.getMonth()+1)+'/'+new_appointment.day.singleDate.jsDate.getDate()+'/'+new_appointment.day.singleDate.jsDate.getFullYear()
				this.create(new_appointment, ()=> {
					if(i == repeat.count - 1) cb();
				}, false);
			}
		}else if(repeat.kind.name == 'Monthly'){
			for (let i = 0; i < repeat.count; i++){
				new_appointment.day.singleDate.date.month++;
				if(new_appointment.day.singleDate.date.month>11){
					new_appointment.day.singleDate.date.month-=12;
					new_appointment.day.singleDate.date.year++;
				}
				new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.date.year, new_appointment.day.singleDate.date.month, new_appointment.day.singleDate.date.day);
				new_appointment.day.singleDate.epoc = new_appointment.day.singleDate.jsDate.getTime();
				new_appointment.day.singleDate.formatted = (new_appointment.day.singleDate.date.month)+'/'+new_appointment.day.singleDate.date.day+'/'+new_appointment.day.singleDate.date.year
				this.create(new_appointment, ()=> {
					if(i == repeat.count - 1) cb();
				}, false);
			}
		}else if(repeat.kind.name == 'Yearly'){
			for (let i = 0; i < repeat.count; i++){
				new_appointment.day.singleDate.date.year++;
				new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.date.year, new_appointment.day.singleDate.date.month, new_appointment.day.singleDate.date.day);
				new_appointment.day.singleDate.epoc = new_appointment.day.singleDate.jsDate.getTime();
				new_appointment.day.singleDate.formatted = (new_appointment.day.singleDate.date.month)+'/'+new_appointment.day.singleDate.date.day+'/'+new_appointment.day.singleDate.date.year
				this.create(new_appointment, ()=> {
					if(i == repeat.count - 1) cb();
				}, false);
			}
		}
		this.refresh.bind(this)
	}
	pre_set(profile, model){
		profile.selected = false;
		if (profile._id) { return; }
		if(!profile.start) profile.start = '08:00';
		if(!profile.day){
			profile.day = {...model};
		}
		profile.day.dateRange = false;
		profile.day.singleDate.jsDate = new Date(profile.day.singleDate.jsDate);
		profile.day.singleDate.epoc = profile.day.singleDate.jsDate.getTime();
		profile.day.singleDate.date = {
			year: profile.day.singleDate.jsDate.getFullYear(),
			month: profile.day.singleDate.jsDate.getMonth()+1,
			day: profile.day.singleDate.jsDate.getDate()
		};
	}
	timeConflictCheck(appointment): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.ps.platform.data.block_booking) {
				this.http.post('/api/appointment/intersect', { ...appointment }, (resp: any) => {
					if(resp) {
						this.alert.destroy();
						this.alert.show({
							text: 'Time conflict! Please change the start time.',
							timeout: 5000,
							class: 'myClass',
							type: 'error',
							closable: true
						});
					}
					resolve(resp);
				});
			} else resolve(true);
		});
	}
	loaded(cb:any=event=>{}) {
		if(this.loadedSubject.getValue() === true) {			
			if(typeof cb === 'function') cb(true);
		} else {
			this.loadedSubject.subscribe((state: boolean) => {
				if(state === true) {
					if(typeof cb === 'function') cb(true);
				}
			});
		}
	}
}
