import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutService, ClosedService, LocationService, ProductService, ServiceService, UserService } from '@services';
import { MongoService } from 'wacom';
import DateHolidays from 'date-holidays';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {	
	public tab: string = 'overview';
    public selector: any = {
		start: new Date(),
		end: new Date()
	};
	public interval: string = 'day';
	public location: any = null;

    private req: any = {};

    private informationLoaded: any = {};
    private keysOverview = ["appts", "newClients", "occupancy", "differenceAppts", "chartAppts", "clients", "chartClients", "topClients", "topServices", "topProducts", "revenue", "chartRevenue", "topStaff"];
    private keysAppointments = ["appts", "occupancy", "appointmentsStatus", "apptsLineChart", "apptsDuration", "apptRows"];
    private keysSales = ["checks", "revenueBarChart", "distribution", "topServices", "topProducts"];
    private keysClients = ["newClientsChart", "clientsAge", "clientsLineChart", "topClients", "noShowsClients"];
    private keysStaff = ["staffOccupancy", "tipLineChart", "tips", "revenueTip"];

	public topClientsRows: any = [];
	public top10ClientsRows: any = [];
	public top10_services: any = [];
	public top5_services: any = [];
	public top10_products: any = [];
	public top5_products: any = [];
	public revenue: string = '0';
	public chartRevenue: any = [];
    public new_clients: any = [];
	public new_clients_columns: number = 1;
	public appts: number = 0;
	public differenceAppts: string = '0%';
	public chartAppts: any = [];
    public clients: any = {
        total: 0,
		difference: '0%',
		new: 0,
		returning: 0,
	};
    public chartClients: any = [];
    public occupancy: any = {
		working_minutes: 0,
		booked_minutes: 0,
		unbooked_minutes: 0,
		percentage: 0,
		value: '0%',
		difference: '0%',
	};
	public topStaff: any = [];
    public appointments_by_status: any = [
		{
			name: 'Completed',
			value: 0,
		}, {
			name: 'Confirmed',
			value: 0,
		}, {
			name: 'No-show',
			value: 0,
		}, {
			name: 'Canceled',
			value: 0,
		}
	];
    public apptsMaxYValue: number = 0;
	public apptsLineChart: any = [
		{
			"name": "Appointments",
			"series": []
		}
	];
	public occupancyBarChart: any = [];
    public appointments_by_duration: any = [
		{
			name: 'Short',
			extra_name: '(0-15 min)',
			value: 0,
		}, {
			name: 'Medium',
			extra_name: '(16-30 min)',
			value: 0,
		}, {
			name: 'Long',
			extra_name: '(31-60 min)',
			value: 0,
		}, {
			name: 'Extended',
			extra_name: '(61-90 min)',
			value: 0,
		}, {
			name: 'Extra-Long',
			extra_name: '(91+ min)',
			value: 0,
		}
	];
	public apptRows = [];
    public distribution = {
		total: 0,
		items: [
			{
				name: 'Services',
				value: 0,
			}, {
				name: 'Products',
				value: 0,
			}, {
				name: 'Tips',
				value: 0,
			}
		]
	};
	public revenueBarChart = [];
	public new_clients_by_sources: any = { 
        total: 0,
        items: [
            {
                name: 'Walk in',
                value: 0,
            }, {
                name: 'Socials',
                value: 0,
            }, {
                name: 'Referral',
                value: 0,
            }, {
                name: 'Advertisement',
                value: 0,
            }
        ]
    };
	public clients_by_age: any = {
        total: 0,
        items: [
            {
                name: 'Under 18',
                value: 0,
            }, {
                name: '18-34',
                value: 0,
            }, {
                name: '35-49',
                value: 0,
            }, {
                name: '50-64',
                value: 0,
            }, {
                name: '65 and Over',
                value: 0,
            }
        ]
    };
	public clientsMaxYValue: number = 0;
    public clientsLineChart: any = [
		{
			"name": "Clients",
			"series": []
		}
	];
	public noShowsRows: any = [];
	public occupancyRows: any = [];
    public tipMaxYValue: number = 0;
	public tipLineChart: any = [
		{
			"name": "Total",
			"series": []
		}, {
			"name": "Average",
			"series": []
		}
	];
    public totalTips = {
		current: '0.00',
		difference: '0%'
	};
    public averageTip = {
		current: '0.00',
		difference: '0%'
	};
    public revenueTipRows: any = [];

	constructor(
        private mongo: MongoService, 
        private router: Router, 
        private route: ActivatedRoute, 
        public loc: LocationService, 
        private datePipe: DatePipe,
        public cs: CheckoutService,
		public us: UserService, 
		public ss: ServiceService,
		public ps: ProductService,
		private cls: ClosedService,
        private http: HttpClient
    ) { }

    getDataFromQueryParams() {
        if(this.route.snapshot.queryParams.location) {
            this.location = this.route.snapshot.queryParams.location;
        }
		if(this.route.snapshot.queryParams.interval && this.route.snapshot.queryParams.selector) {
			this.interval = this.route.snapshot.queryParams.interval;
			switch(this.interval) {
				case 'day':
					this.selector = {
						start: new Date(this.route.snapshot.queryParams.selector),
						end: new Date(this.route.snapshot.queryParams.selector)
					};
					break;
				case 'week': 
					const d_week = new Date(this.route.snapshot.queryParams.selector);
					const day_week = d_week.getDay();
					const diff_week = day_week === 0 ? -6 : 1 - day_week;
					const start_week = new Date(d_week.setDate(d_week.getDate() + diff_week));
					const end_week = new Date(d_week.setDate(d_week.getDate() + 6));
					this.selector = {
						start: start_week,
						end: end_week
					};
					break;
				case 'month':
					const start_month = new Date(new Date(this.route.snapshot.queryParams.selector).getFullYear(), new Date(this.route.snapshot.queryParams.selector).getMonth(), 1);
					const end_month = new Date(new Date(this.route.snapshot.queryParams.selector).getFullYear(), new Date(this.route.snapshot.queryParams.selector).getMonth() + 1, 0);
					this.selector = {
						start: start_month,
						end: end_month
					};
					break;
				case 'quarter':
					const d_quarter = new Date(this.route.snapshot.queryParams.selector);
					if (d_quarter.getMonth() >= 0 && d_quarter.getMonth() <= 2) {
						this.selector = {
							start: new Date(d_quarter.getFullYear(), 0, 1),
							end: new Date(d_quarter.getFullYear(), 3, 0)
						};
					} else if (d_quarter.getMonth() >= 3 && d_quarter.getMonth() <= 5) {
						this.selector = {
							start: new Date(d_quarter.getFullYear(), 3, 1),
							end: new Date(d_quarter.getFullYear(), 6, 0)
						};
					} else if (d_quarter.getMonth() >= 6 && d_quarter.getMonth() <= 8) {
						this.selector = {
							start: new Date(d_quarter.getFullYear(), 6, 1),
							end: new Date(d_quarter.getFullYear(), 9, 0)
						};
					} else {
						this.selector = {
							start: new Date(d_quarter.getFullYear(), 9, 1),
							end: new Date(d_quarter.getFullYear(), 12, 0)
						};
					}
					break;
				case 'year':
					const d_year = new Date(this.route.snapshot.queryParams.selector);
					this.selector = {
						start: new Date(d_year.getFullYear(), 0, 1),
						end: new Date(d_year.getFullYear(), 11, 31)
					};
					break;
			}
		}        
    }

    resetAllData() {
        this.informationLoaded = {};

        this.topClientsRows = [];
        this.top10ClientsRows = [];
        this.top10_services = [];
        this.top5_services = [];
        this.top10_products = [];
        this.top5_products = [];
        this.revenue = '0';
        this.chartRevenue = [];
        this.new_clients = [];
        this.new_clients_columns = 1;
        this.appts = 0;
        this.differenceAppts = '0%';
        this.chartAppts = [];
        this.clients = { total: 0, difference: '0%', new: 0, returning: 0, };
        this.chartClients = [];
        this.occupancy = { working_minutes: 0, booked_minutes: 0, unbooked_minutes: 0, percentage: 0, value: '0%', difference: '0%', };
        this.topStaff = [];
        this.appointments_by_status = [ { name: 'Completed', value: 0, }, { name: 'Confirmed', value: 0, }, { name: 'No-show', value: 0, }, { name: 'Canceled', value: 0, } ];
        this.apptsMaxYValue = 0;
        this.apptsLineChart = [ { "name": "Appointments", "series": [] } ];
        this.occupancyBarChart = [];
        this.appointments_by_duration = [ { name: 'Short', extra_name: '(0-15 min)', value: 0, }, { name: 'Medium', extra_name: '(16-30 min)', value: 0, }, { name: 'Long', extra_name: '(31-60 min)', value: 0, }, { name: 'Extended', extra_name: '(61-90 min)', value: 0, }, { name: 'Extra-Long', extra_name: '(91+ min)', value: 0, } ];
	    this.apptRows = [];
        this.distribution = { total: 0, items: [ { name: 'Services', value: 0, }, { name: 'Products', value: 0, }, { name: 'Tips', value: 0, } ] };
	    this.revenueBarChart = [];
        this.new_clients_by_sources = { total: 0, items: [ { name: 'Walk in', value: 0, }, { name: 'Socials', value: 0, }, { name: 'Referral', value: 0, }, { name: 'Advertisement', value: 0, } ] };
        this.clients_by_age = { total: 0, items: [ { name: 'Under 18', value: 0, }, { name: '18-34', value: 0, }, { name: '35-49', value: 0, }, { name: '50-64', value: 0, }, { name: '65 and Over', value: 0, } ] };
	    this.clientsMaxYValue = 0;
        this.clientsLineChart = [ { "name": "Clients", "series": [] } ];
    	this.noShowsRows = [];
        this.occupancyRows = [];
        this.tipMaxYValue = 0;
        this.tipLineChart = [ { "name": "Total", "series": [] }, { "name": "Average", "series": [] } ];
        this.totalTips = { current: '0.00', difference: '0%' };
        this.averageTip = { current: '0.00', difference: '0%' };
        this.revenueTipRows = [];

        switch(this.tab) {
            case 'overview': 
                this.tabOverview();
                break;
		    case 'appointments':
                this.tabAppointments();
                break;
		    case 'sales':
                this.tabSales();
                break;
		    case 'clients':
                this.tabClients();
                break;
		    case 'staff':
                this.tabStaff();
                break;
        }
    }

    periodChange(event) {
		this.selector = {
			start: event.selector.start,
			end: event.selector.end
		};
		this.interval = event.interval;
		this.router.navigate([],{ 
			queryParams: { 
				interval: this.interval,
				selector: this.datePipe.transform(this.selector.start, 'yyyy-MM-dd')
			}, 
			queryParamsHandling: 'merge', 
			fragment: 'tab=' + this.tab 
		});
	}

	locationChange(event) {
		const queryParams = { ...this.route.snapshot.queryParams };

  		if(event) queryParams.location = event;
		else delete queryParams['location'];

		this.router.navigate([],{ 
			queryParams: queryParams, 
			fragment: 'tab=' + this.tab 
		});
	}

    tabOverview() {
        this.addingKeys(this.keysOverview);
        this.requestGeneration();

        this.initializeTopClients();
        this.initializeTopServices();
        this.initializeTopProducts();
        this.initializeRevenue();
        this.initializeChartRevenue();
		this.mongo.on('user', () => {
			this.initializeNewClients();
		});
        this.initializeDifferenceAppts();
        this.initializeChartAppts();
        this.initializeClients();
        this.initializeChartClients();        
        this.initializeOccupancy();
		this.initializeTopStaff();
    }
    
    tabAppointments() {
        this.addingKeys(this.keysAppointments);
        this.requestGeneration();

        this.initializeAppointments();
        this.initializeAppointmentsStatus();
        this.initializeApptsLineChart();
        this.initializeApptsDuration();
        this.initializeApptRows();
        if(this.interval == 'day') {
            this.initializeOccupancy();
        } else {
            this.initializeOccupancyBarChart();
        }
    }

    tabSales() {
        this.addingKeys(this.keysSales);
        this.requestGeneration();

        this.initializeDistribution();
        this.initializeTopServices();
        this.initializeTopProducts();
        this.initializeRevenueBarChart();
        this.initializeChecks();
    }

    tabClients() {
        this.addingKeys(this.keysClients);
        this.requestGeneration();

        this.initializeClientsAge();
        this.initializeClientsLineChart();
        this.mongo.on('user', () => {
			this.initializeNewClientsChart();
		});
        this.initializeTopClients();
        this.initializeNoShowsClients();
    }

    tabStaff() {
        this.addingKeys(this.keysStaff);
        this.requestGeneration();

        this.initializeStaffOccupancy();
        this.initializeTips();
        this.initializeTipLineChart();
        this.initializeRevenueAndTip();
    }

    private addingKeys(keys) {
        keys.forEach(key => {
            if (!(key in this.informationLoaded)) {
                this.informationLoaded[key] = false;
            }
        });
    }

    tabLoaded(tab, cb:any=event=>{}) {
        var keysToFilter = [];        

        switch(tab) {
            case 'overview': 
                keysToFilter = this.keysOverview;
                break;
		    case 'appointments':
                keysToFilter = this.keysAppointments;
                break;
		    case 'sales':
                keysToFilter = this.keysSales;
                break;
		    case 'clients':
                keysToFilter = this.keysClients;
                break;
            case 'staff':
                keysToFilter = this.keysStaff;
                break;
        }
        
        let waitForInformationLoading = setInterval(() => {
            if (Object.values(
                    Object.keys(this.informationLoaded)
                    .filter(key => keysToFilter.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = this.informationLoaded[key];
                        return obj;
                    }, {})
                )
                .every(value => value === true)) {
                clearInterval(waitForInformationLoading);
                if(typeof cb === 'function') cb(true);
            }
        }, 1);
    }

    private requestGeneration() {
        this.req = {
            interval: this.interval,
            location: this.location,
            ...this.getRequestSelector(this.selector)
        };
    }

    private getRequestSelector(selector) {
        const req: any = {};
        
        if(selector) {
            switch(this.interval) {
                case 'day':
                    req.date = `${selector?.start.getMonth() + 1}/${selector?.start.getDate()}/${selector?.start.getFullYear()}`;
                    break;
                case 'week':
                    req.dates = [];
                    for (let d = new Date(selector?.start); d <= new Date(selector?.end); d.setDate(d.getDate() + 1)) {
                        req.dates.push(`${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`);
                    }
                    break;
                case 'month':
                    req.month = selector?.start.getMonth() + 1;
                    req.year = selector?.start.getFullYear();
                    break;
                case 'year':
                    req.year = selector?.start.getFullYear();
                    break;
                case 'quarter':
                    req.months = [];
                    for (let d = new Date(selector?.start); d <= new Date(selector?.end); d.setMonth(d.getMonth() + 1)) {
                        req.months.push(d.getMonth() + 1);
                    }
                    req.year = selector?.start.getFullYear();
                    break;
            }
        }

        return req;
    }

    private getPreviousSelector() {
        const previous: any = {};

		switch(this.interval) {
			case 'day':
				let date = new Date(this.selector.start);
				date.setDate(new Date(this.selector.start).getDate() - 1);
				previous.start = date;
				previous.end = date;
				break;
			case 'week':
				let weekStart = new Date(this.selector.start);
				weekStart.setDate(this.selector.start.getDate() - 7);
				let weekEnd = new Date(this.selector.end);
				weekEnd.setDate(this.selector.end.getDate() - 7);
				previous.start = weekStart;
				previous.end = weekEnd;
				break;
			case 'month':
				let monthStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 1, 1);
				let monthEnd = new Date(this.selector.end.getFullYear(), this.selector.end.getMonth(), 0);
				previous.start = monthStart;
				previous.end = monthEnd;
				break;
			case 'year':
				let yearStart = new Date(this.selector.start.getFullYear() - 1, 0, 1);
				let yearEnd = new Date(this.selector.end.getFullYear() - 1, 11, 31);
				previous.start = yearStart;
				previous.end = yearEnd;
				break;
			case 'quarter':
				let quarterStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 3, 1);
				let quarterEnd = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth(), 0);
				previous.start = quarterStart;
				previous.end = quarterEnd;
				break;
		}

        return previous;
    }

    private initializeChecks(cb:any=event=>{}) {
        this.cs.getChecks(this.interval, this.selector, this.location).then(resp => {
            this.informationLoaded.checks = true;
            if(typeof cb === 'function') cb(true);
        }).catch(error => {
            console.error('Error:', error);
        });
    }

    private initializeTopClients() {
		this.topClientsRows = [];
		this.top10ClientsRows = [];

        this.http.post('/api/dashboard/top_clients', this.req).subscribe((resp:any) => {
            this.topClientsRows = [...resp];
            resp.splice(10);
            this.top10ClientsRows = [...resp];
		    this.informationLoaded.topClients = true;
        });
	}

    private initializeTopServices() {
        this.top10_services = [];
        this.top5_services = [];

        this.http.post('/api/dashboard/top_services', this.req).subscribe((resp:any) => {
            this.top10_services = [...resp];
            resp.splice(5);
            this.top5_services = [...resp];
		    this.informationLoaded.topServices = true;
        });
	}

    private initializeTopProducts() {
		this.top10_products = [];
        this.top5_products = [];

        this.http.post('/api/dashboard/top_products', this.req).subscribe((resp:any) => {
            this.top10_products = [...resp];
            resp.splice(5);
            this.top5_products = [...resp];
		    this.informationLoaded.topProducts = true;
        });
	}

    private addMoneyAbbreviation(amount) {
		amount = Number(amount);
		const abbreviations = ['', 'K', 'M', 'B', 'T'];
		let index = 0;
	  
		while (amount >= 1000 && index < abbreviations.length - 1) {
			amount /= 1000;
			index++;
		}
	  
		return amount.toFixed(2) + abbreviations[index];
	}

    private initializeRevenue() {
        this.http.post('/api/dashboard/revenue', this.req).subscribe((resp:any) => {
            this.revenue = this.addMoneyAbbreviation(resp.revenue || 0);
		    this.informationLoaded.revenue = true;
        });
	}

    private initializeChartRevenue() {
        this.http.post('/api/dashboard/chart_revenue', this.req).subscribe((resp:any) => {
            var buf = []

            switch(this.interval) {
                case 'day':
                    buf = Array(12).fill(0);
                    resp.forEach(result => {
                        if (result._id !== "Other") {
                            const index = Math.floor(result._id / 2);
                            buf[index] = result.revenue;
                        }
                    });
                    break;
                case 'week':
                    buf = Array(14).fill(0);
                    resp.forEach(result => {                    
                        buf[this.req.dates.findIndex(d => d === result.interval) * 2 + ( result._id.timeCategory === 'after_lunch' ? 1 : 0 ) ] = result.revenue;
                    });
                    break;
                case 'month':
                    buf = Array(15).fill(0);
                    resp.forEach(result => {
                        if (result._id !== "Other") {
                            const index = Math.floor(result._id / 3);
                            buf[index] = result.revenue;
                        }
                    });
                    break;
                case 'year':
                    buf = Array(12).fill(0);        
                    resp.forEach(result => {
                        buf[result._id-1] = result.revenue;
                    });
                    break;
                case 'quarter':
                    const number_of_days = this.req.months.reduce((accumulator, currentValue) => accumulator + new Date(this.req.year, currentValue, 0).getDate(), 0);
                    buf = Array(Math.ceil(number_of_days / 7)).fill(0);     
                    resp.forEach(result => {
                        let days_before = 0
                        for(let i = 0; i < this.req.months.length; i++) {
                            if(this.req.months[i] == result._id.month) break;
                            days_before += new Date(this.req.year, this.req.months[i], 0).getDate();
                        }
                        buf[Math.ceil((days_before + result._id.day) / 7) - 1] += result.revenue;
                    });
                    break;
            }

            this.chartRevenue = this.generateBarChart(buf);
		    this.informationLoaded.chartRevenue = true;
        });
	}

    private generateBarChart(data) {
		const items = [...data]; 
		const columnWidth = 5;
		const columnSpacing = 2.5;
		const svgWidth = items.length * (columnWidth + columnSpacing);
		const svgHeight = 70;
		const maxValue = Math.max(...items);
		const value = (svgHeight - (columnWidth * 2)) / maxValue;

		const res = {
			width: svgWidth,
			height: svgHeight,
			lines: []
		};
	  
		for (let i = 0; i < data.length; i++) {
			const x = (columnWidth + columnSpacing) * i;
			res.lines.push({
				x1: columnWidth + x,
				y1: svgHeight - columnWidth,
				x2: columnWidth + x,
				y2: data[i] ? (svgHeight - columnWidth) - (value * data[i]) : svgHeight - columnWidth
			})
		}
	  
		return res;
	}

    private initializeNewClients() {
		this.new_clients = [];
		this.new_clients_columns = 1;

		for(let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
            if(this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]) {
				for(let client of this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]) {
					if(!this.new_clients.find((c) => c._id == client._id)) {
						this.new_clients.push(client);
					}
				}
			}
		}

		this.new_clients_columns = Math.ceil(this.new_clients.length / 10);
		this.informationLoaded.newClients = true;
	}

    private initializeNewClientsChart() {
		this.new_clients_by_sources = { 
            total: 0,
            items: [
                {
                    name: 'Walk in',
                    value: 0,
                }, {
                    name: 'Socials',
                    value: 0,
                }, {
                    name: 'Referral',
                    value: 0,
                }, {
                    name: 'Advertisement',
                    value: 0,
                }
            ]
        };

		for(let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
            if(this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]) {
                this.new_clients_by_sources.total += this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]?.length;
				for(let client of this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]) {
                    if(client.data.source) {
						if(this.new_clients_by_sources.items.find(s => s.name == client.data.source)) this.new_clients_by_sources.items.find(s => s.name == client.data.source).value++;
					}
				}
			}
		}

		this.informationLoaded.newClientsChart = true;
	}

    private initializeDifferenceAppts() {
		const previous = this.getPreviousSelector();

        this.http.post('/api/dashboard/appointments', { ...this.req, previous: this.getRequestSelector(previous) }).subscribe((resp:any) => {
            this.appts = resp.current || 0;
            if (resp.previous) {
                const percentageChange = ((resp.current - resp.previous) / resp.previous) * 100;
                this.differenceAppts = (percentageChange > 0 ? '+' : '') + (Math.round(percentageChange * 100) / 100).toString() + '%';
            } else if(resp.current){
                this.differenceAppts = '+100%';
            } else {
                this.differenceAppts = '0%';
            }

		    this.informationLoaded.appts = true;
		    this.informationLoaded.differenceAppts = true;
        });
	}

    private initializeChartAppts() {
        this.http.post('/api/dashboard/chart_appointments', this.req).subscribe((resp:any) => {            
            var buf = []

            switch(this.interval) {
                case 'day':
                    buf = Array(24).fill(0);
                    resp.forEach(result => {
                        buf[result._id] = result.appointments;
                    });
                    break;
                case 'week':
                    buf = Array(7).fill(0);
                    resp.forEach(result => {                    
                        buf[this.req.dates.findIndex(d => d === result._id)] = result.appointments;
                    });
                    break;
                case 'month':                
                    buf = Array(new Date(this.req.year, this.req.month, 0).getDate()).fill(0);
                    resp.forEach(result => {
                        if (result._id !== "Other") {
                            buf[result._id - 1] = result.appointments;
                        }
                    });
                    break;
                case 'year':
                    buf = Array(12).fill(0);        
                    resp.forEach(result => {
                        buf[result._id-1] = result.appointments;
                    });
                    break;
                case 'quarter':
                    const number_of_days = this.req.months.reduce((accumulator, currentValue) => accumulator + new Date(this.req.year, currentValue, 0).getDate(), 0);
                    buf = Array(Math.ceil(number_of_days / 7)).fill(0);     
                    resp.forEach(result => {
                        let days_before = 0
                        for(let i = 0; i < this.req.months.length; i++) {
                            if(this.req.months[i] == result._id.month) break;
                            days_before += new Date(this.req.year, this.req.months[i], 0).getDate();
                        }
                        buf[Math.ceil((days_before + result._id.day) / 7) - 1] += result.appointments;
                    });
                    break;
            }

            this.chartAppts = this.generateLineChart(buf);
		    this.informationLoaded.chartAppts = true;
        });
	}

	private generateLineChart(dataSet) {
		const svgWidth = 75;
		const svgHeight = 65;
		const items = [...dataSet];
		const maxValue = items.sort((a, b) => b - a)[0];
		const minValue = items.sort((a, b) => a - b)[0];
		const length = items.length;
		const list = dataSet.map((value, index) => {
		  	const newValue = Math.floor(((value - minValue) / (maxValue - minValue) || 0) * 100 * 100) / 100;
	
			const adjustedYValue = Math.floor((newValue * svgHeight) / 100);
			const adjustedXValue = Math.floor(index * (svgWidth / (length + 1)));
            
			return adjustedXValue + ' , ' + adjustedYValue;
		});
		return maxValue > 0 ? list : [];
	}

    private initializeClients() {
		const previous = this.getPreviousSelector();

        this.http.post('/api/dashboard/clients', { ...this.req, previous: this.getRequestSelector(previous) }).subscribe((resp:any) => {
		    var difference = '0%';
            if (resp.previous) {
                const percentageChange = ((resp.current - resp.previous) / resp.previous) * 100;
                difference = (percentageChange > 0 ? '+' : '') + (Math.round(percentageChange * 100) / 100).toString() + '%';
            } else if(resp.current){
                difference = '+100%';
            } else {
                difference = '0%';
            }
    
            this.clients = {
                total: resp.current,
                difference: difference,
            };
    
            this.informationLoaded.clients = true;
        });
	}

    private initializeChartClients() {
        this.http.post('/api/dashboard/clients_line_chart', this.req).subscribe((resp:any) => {
            var chart = []            
            
            switch(this.interval) {
                case 'day':
                    chart = Array(24).fill(0);
                    resp.forEach(result => {
                        chart[result._id] = result.count;
                    });
                    break;
                case 'week':
                    chart = Array(7).fill(0);
                    resp.forEach(result => {                    
                        chart[this.req.dates.findIndex(d => d === result._id)] = result.count;
                    });
                    break;
                case 'month':
                    chart = Array(new Date(this.req.year, this.req.month, 0).getDate()).fill(0);
                    resp.forEach(result => {
                        chart[result._id - 1] = result.count;
                    });
                case 'year':
                    chart = Array(12).fill(0);        
                    resp.forEach(result => {
                        chart[result._id-1] = result.count;
                    });
                    break;
                case 'quarter':
                    const number_of_days = this.req.months.reduce((accumulator, currentValue) => accumulator + new Date(this.req.year, currentValue, 0).getDate(), 0);
                    let buf = Array(Math.ceil(number_of_days / 7)).fill([]);     
                    resp.forEach(result => {
                        let days_before = 0
                        for(let i = 0; i < this.req.months.length; i++) {
                            if(this.req.months[i] == result._id.month) break;
                            days_before += new Date(this.req.year, this.req.months[i], 0).getDate();
                        }
                        buf[Math.ceil((days_before + result._id.day) / 7) - 1] = [...new Set([...buf[Math.ceil((days_before + result._id.day) / 7) - 1], ...result.clients || []])];
                    });
                    chart = buf.map((value) => value?.length);
                    break;
            }

            this.chartClients = this.generateLineChart(chart);
            this.informationLoaded.chartClients = true;
        });
    }

    private initializeOccupancy() {
		const previous = this.getPreviousSelector();

        this.http.post('/api/dashboard/appointments_all_minutes', { ...this.req, previous: this.getRequestSelector(previous) }).subscribe((resp:any) => {
            this.mongo.on('user location closed', () => {
                switch(this.interval) {
                    case 'day':
                        let date = new Date(this.selector.start);
                        date.setDate(new Date(this.selector.start).getDate() - 1);
                        var prev = this.getOccupancyHours(date, date);
                        break;
                    case 'week':
                        let weekStart = new Date(this.selector.start);
                        weekStart.setDate(this.selector.start.getDate() - 7);
                        let weekEnd = new Date(this.selector.end);
                        weekEnd.setDate(this.selector.end.getDate() - 7);
                        var prev = this.getOccupancyHours(weekStart, weekEnd);
                        break;
                    case 'month':
                        let monthStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 1, 1);
                        let monthEnd = new Date(this.selector.end.getFullYear(), this.selector.end.getMonth(), 0);
                        var prev = this.getOccupancyHours(monthStart, monthEnd);
                        break;
                    case 'year':
                        let yearStart = new Date(this.selector.start.getFullYear() - 1, 0, 1);
                        let yearEnd = new Date(this.selector.end.getFullYear() - 1, 11, 31);
                        var prev = this.getOccupancyHours(yearStart, yearEnd);
                        break;
                    case 'quarter':
                        let quarterStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 3, 1);
                        let quarterEnd = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth(), 0);
                        var prev = this.getOccupancyHours(quarterStart, quarterEnd);
                        break;
                }
                const working_minutes = this.getOccupancyHours(this.selector.start, this.selector.end);
                const booked_minutes = resp.current || 0;
                const percentage = booked_minutes ? Math.round(booked_minutes * 100 / working_minutes) : 0;
                const prevPercentage = resp.previous ? Math.round(resp.previous * 100 / prev) : 0;

                this.occupancy = {
                    working_minutes: working_minutes,
                    booked_minutes: booked_minutes,
                    unbooked_minutes: working_minutes - booked_minutes,
                    percentage: percentage,
                    value: `${percentage}%`,
                    difference: ((percentage - prevPercentage) > 0 ? '+' : '') + (Math.round(percentage - prevPercentage)).toString() + '%'
                };
                
                this.informationLoaded.occupancy = true;
            });
        });

	}

    private getOccupancyHours(startDate, endDate) {
		var res = 0;
        
        for(let location of this.loc.locations) {
            if(!this.location || (this.location == location._id)) {

                const closeds = this.getClosedForLocation(location, startDate);

                for(let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
                    d.setHours(0, 0, 0, 0);
                    let day = this.datePipe.transform(new Date(d), 'EEEE');
                    let format = this.datePipe.transform(new Date(d), 'M/d/yyyy');
                
                    if(location.data.business_hours?.[day]?.length) {

                        let closed = false;
                        for (let i = closeds.length-1; i >= 0; i--){
                            if(
                                new Date(closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
                                new Date(closeds[i].end?.singleDate?.formatted) >= new Date(d)
                            ) {                                                  					
                                closed = true;
                                break;
                            }
                        } 

                        if(!closed) {							
                            for(let staff of this.us.allowed_appointments) {

                                if(staff.location.find(l => l == location._id)) {

                                    if(staff.data.working_hours?.[format]?.hours?.length && !staff.data.working_hours?.[format]?.vacation) {
                                        res += this.findIntersection(location.data.business_hours[day], staff.data.working_hours[format].hours);
                                    } else if(staff.data.working_hours?.default?.[day]?.length && !staff.data.working_hours?.[format]?.vacation) {
                                        res += this.findIntersection(location.data.business_hours[day], staff.data.working_hours.default[day]);
                                    }
                                }
                            }
                        } 
                    }
                }	
            }
		}

		return res;
	}

    private findIntersection(array1: any[], array2: any[]): number {
		const timeToMinutes = (time: string): number => {
			const [hours, minutes] = time.split(':').map(Number);
			return hours * 60 + minutes;
		}
		let intersectionMinutes = 0;
	
		for (const interval1 of array1) {
			const start1 = timeToMinutes(interval1.from);
			const end1 = timeToMinutes(interval1.to);
		
			for (const interval2 of array2) {
				const start2 = timeToMinutes(interval2.from);
				const end2 = timeToMinutes(interval2.to);
		
				// Calculate the intersection
				const intersectionStart = Math.max(start1, start2);
				const intersectionEnd = Math.min(end1, end2);
		
				// If there's a valid intersection, add it to the total intersection minutes
				if (intersectionStart < intersectionEnd) {
					intersectionMinutes += intersectionEnd - intersectionStart;
				}
			}
		}
	
		return intersectionMinutes;
	}

    private initializeTopStaff() {
		const staffs = [];

		this.http.post('/api/dashboard/appointments_staff_minutes', this.req).subscribe((resp:any) => {
            const staff_appointments = resp;

            this.mongo.on('user location closed', () => {
				for(let location of this.loc.locations) {
                    if(!this.location || (this.location == location._id)) {

                        const closeds = this.getClosedForLocation(location, this.selector.start);
                        
                        for(let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                            d.setHours(0, 0, 0, 0);
                            let day = this.datePipe.transform(new Date(d), 'EEEE');
                            let format = this.datePipe.transform(new Date(d), 'M/d/yyyy');
                        
                            if(location.data.business_hours?.[day]?.length) {
                                
                                let closed = false;
                                for (let i = closeds.length-1; i >= 0; i--){
                                    if(
                                        new Date(closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
                                        new Date(closeds[i].end?.singleDate?.formatted) >= new Date(d)
                                    ) {                                                  					
                                        closed = true;
                                        break;
                                    }
                                } 
            
                                if(!closed) {	
                                    
                                    for(let staff of this.us.allowed_appointments) {
            
                                        if(staff.location.find(l => l == location._id)) {
                                            let working_minutes = 0;
            
                                            if(staff.data.working_hours?.[format]?.hours?.length && !staff.data.working_hours?.[format]?.vacation) {
                                                working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours[format].hours);
                                            } else if(staff.data.working_hours?.default?.[day]?.length && !staff.data.working_hours?.[format]?.vacation) {
                                                working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours.default[day]);
                                            }

											if(!staffs.find(r => r._id === staff._id)) {
												staff.working_minutes = working_minutes;
												staff.visit_minutes = staff_appointments.find((s: any) => s._id === staff._id)?.value || 0;
												staff.occupancy = working_minutes > 0 && staff.visit_minutes ? Math.round(staff.visit_minutes * 100 / working_minutes) : 0;
												staffs.push(staff);
                                            } else {
												const index = staffs.findIndex(r => r._id === staff._id);
                                                staffs[index].working_minutes += working_minutes;
                                                staffs[index].occupancy = staffs[index].working_minutes > 0 && staffs[index].visit_minutes ? Math.round(staffs[index].visit_minutes * 100 / staffs[index].working_minutes) : 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

				staffs.sort((a, b) => b.occupancy - a.occupancy);
				staffs.splice(10);

				this.topStaff = staffs.filter((a) => a.occupancy > 0);
				this.informationLoaded.topStaff = true;
			});
		});
	}

    private initializeAppointments() {
        this.http.post('/api/dashboard/appointments', this.req).subscribe((resp:any) => {
            this.appts = resp.current || 0;
		    this.informationLoaded.appts = true;
        });
    }

    private initializeAppointmentsStatus() {
        this.http.post('/api/dashboard/appointments_status', this.req).subscribe((resp:any) => {
            const appointments_by_status = [...this.appointments_by_status];
            appointments_by_status.forEach((status) => {
                status.value = resp.find(element => element.name == status.name)?.value || 0;
            });
            this.appointments_by_status = appointments_by_status;

            this.informationLoaded.appointmentsStatus = true;
        });
	}

    private initializeApptsLineChart() {
        this.http.post('/api/dashboard/appointments_line_chart', this.req).subscribe((resp:any) => {
            this.apptsMaxYValue = 0;
            this.apptsLineChart = [
                {
                    "name": "Appointments",
                    "series": []
                }
            ];

            switch(this.interval) {
                case 'day':
                    for(let i = 0; i < 24; i++) {
                        const value = resp.find((element: any) => element._id === i)?.appointments || 0;
                        
                        let d = new Date(this.selector.start);
                        d.setHours(i, 0, 0);

                        if(value > this.apptsMaxYValue) this.apptsMaxYValue = value;
                        
                        this.apptsLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortTime'),
                            value: value,
                            title: this.datePipe.transform(d, 'EEEE d MMMM, h:mm a')
                        });
                    }
                    break;
                case 'week':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                        const value = resp.find((element: any) => element._id === d.getDate())?.appointments || 0;

                        if(value > this.apptsMaxYValue) this.apptsMaxYValue = value;

                        this.apptsLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortDate'),
                            value: value,
                            title: this.datePipe.transform(d, 'EEEE d MMMM')
                        });
                    }
                    break;
                case 'month':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                        const value = resp.find((element: any) => element._id === d.getDate())?.appointments || 0;

                        if(value > this.apptsMaxYValue) this.apptsMaxYValue = value;
                        
                        this.apptsLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortDate'),
                            value: value,
                            title: this.datePipe.transform(d, 'EEEE d MMMM')
                        });
                    }
                    break;
                case 'year':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
                        const value = resp.find((element: any) => element._id === (d.getMonth() + 1))?.appointments || 0;

                        if(value > this.apptsMaxYValue) this.apptsMaxYValue = value;
                        
                        this.apptsLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'MMM'),
                            value: value,
                            title: this.datePipe.transform(d, 'MMMM')
                        });
                    }
                    break;
                case 'quarter':
                    for (let start = new Date(this.selector.start); start <= new Date(this.selector.end); start.setDate(start.getDate() + 7)) {
                        let end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
                        if(end > this.selector.end) end = new Date(this.selector.end);

                        var value = 0;

                        for (let d = new Date(start); d <= new Date(end); d.setDate(d.getDate() + 1)) {                            
                            value += resp.find((element: any) => element._id === this.datePipe.transform(d, 'M/d/yyyy'))?.appointments || 0;
                        }
                        
                        if(value > this.apptsMaxYValue) this.apptsMaxYValue = value;
                        
                        this.apptsLineChart[0].series.push({
                            name: this.datePipe.transform(start, 'shortDate'),
                            value: value,
                            title: ((this.datePipe.transform(start, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(start, 'd') : this.datePipe.transform(start, 'd MMMM')) + (end.getDate() != start.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))
                        });
                    }
                    break;
            }

            this.informationLoaded.apptsLineChart = true;
        });
	}

    private initializeOccupancyBarChart() {
        this.http.post('/api/dashboard/appointments_minutes', this.req).subscribe((resp:any) => {
            const appointments = resp;
            this.mongo.on('user location closed', () => {
                this.occupancy.working_minutes = 0;
                this.occupancyBarChart = [];

                switch(this.interval) {
                    case 'week':
                        for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                            let working_minutes = this.getOccupancyHours(d, d);
                            this.occupancy.working_minutes += working_minutes;
                            let booked_minutes = appointments.find((a: any) => a._id === d.getDate())?.value || 0;
                            let percentage = booked_minutes ? Math.round(booked_minutes * 100 / working_minutes) : 0;
                            let unbooked_minutes = working_minutes - booked_minutes;

                            this.occupancyBarChart.push({
                                name: this.datePipe.transform(d, 'shortDate'),
                                series: [
                                    {
                                        name: 'Booked hours',
                                        value: (booked_minutes / 60),
                                        title: `Booked \u2022 ${this.datePipe.transform(d, 'EEEE d MMMM')}`,
                                        tooltip_value: `${this.formatMinutesToHoursAndMinutes(booked_minutes) } (${percentage}%)`
                                    }, {
                                        name: 'Unbooked hours',
                                        value: (unbooked_minutes / 60),
                                        title: `Unbooked \u2022 ${this.datePipe.transform(d, 'EEEE d MMMM')}`,
                                        tooltip_value: `${this.formatMinutesToHoursAndMinutes(unbooked_minutes)} (${100 - percentage}%)`
                                    }
                                ]
                            });
                        }
                        break;
                    case 'month':
                        for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                            let working_minutes = this.getOccupancyHours(d, d);
                            this.occupancy.working_minutes += working_minutes;
                            let booked_minutes = appointments.find((a: any) => a._id === d.getDate())?.value || 0;
                            let percentage = booked_minutes ? Math.round(booked_minutes * 100 / working_minutes) : 0;
                            let unbooked_minutes = working_minutes - booked_minutes;

                            this.occupancyBarChart.push({
                                name: this.datePipe.transform(d, 'shortDate'),
                                series: [
                                    {
                                        name: 'Booked hours',
                                        value: (booked_minutes / 60),
                                        title: `Booked \u2022 ${this.datePipe.transform(d, 'EEEE d MMMM')}`,
                                        tooltip_value: `${this.formatMinutesToHoursAndMinutes(booked_minutes) } (${percentage}%)`
                                    }, {
                                        name: 'Unbooked hours',
                                        value: (unbooked_minutes / 60),
                                        title: `Unbooked \u2022 ${this.datePipe.transform(d, 'EEEE d MMMM')}`,
                                        tooltip_value: `${this.formatMinutesToHoursAndMinutes(unbooked_minutes)} (${100 - percentage}%)`
                                    }
                                ]
                            });
                        }
                        break;
                    case 'year':
                        for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
                            let end = new Date(d.getFullYear(), d.getMonth() + 1, 0);

                            let working_minutes = this.getOccupancyHours(d, end);
                            this.occupancy.working_minutes += working_minutes;
                            let booked_minutes = appointments.find((a: any) => a._id === (d.getMonth() + 1))?.value || 0;
                            let percentage = booked_minutes ? Math.round(booked_minutes * 100 / working_minutes) : 0;
                            let unbooked_minutes = working_minutes - booked_minutes;

                            this.occupancyBarChart.push({
                                name: this.datePipe.transform(d, 'MMMM'),
                                series: [
                                    {
                                        name: 'Booked hours',
                                        value: (booked_minutes / 60),
                                        title: `Booked \u2022 ${this.datePipe.transform(d, 'MMMM')}`,
                                        tooltip_value: `${this.formatMinutesToHoursAndMinutes(booked_minutes) } (${percentage}%)`
                                    }, {
                                        name: 'Unbooked hours',
                                        value: (unbooked_minutes / 60),
                                        title: `Unbooked \u2022 ${this.datePipe.transform(d, 'MMMM')}`,
                                        tooltip_value: `${this.formatMinutesToHoursAndMinutes(unbooked_minutes)} (${100 - percentage}%)`
                                    }
                                ]
                            });
                        }
                        break;
                    case 'quarter':
                        for (let start = new Date(this.selector.start); start <= new Date(this.selector.end); start.setDate(start.getDate() + 7)) {
                            let end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
                            if(end > this.selector.end) end = new Date(this.selector.end);
    
                            let working_minutes = this.getOccupancyHours(start, end);
                            this.occupancy.working_minutes += working_minutes;
                            let booked_minutes = 0;
                            
                            for (let d = new Date(start); d <= new Date(end); d.setDate(d.getDate() + 1)) {                            
                                booked_minutes += appointments.find((a: any) => a._id === this.datePipe.transform(d, 'M/d/yyyy'))?.value || 0;
                            }

                            let percentage = booked_minutes ? Math.round(booked_minutes * 100 / working_minutes) : 0;
                            let unbooked_minutes = working_minutes - booked_minutes;
                            
                            this.occupancyBarChart.push({
                                name: this.datePipe.transform(start, 'shortDate'),
                                series: [
                                    {
                                        name: 'Booked hours',
                                        value: (booked_minutes / 60),
                                        title: `Booked \u2022 ${((this.datePipe.transform(start, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(start, 'd') : this.datePipe.transform(start, 'd MMMM')) + (end.getDate() != start.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))}`,
                                        tooltip_value: `${this.formatMinutesToHoursAndMinutes(booked_minutes) } (${percentage}%)`
                                    }, {
                                        name: 'Unbooked hours',
                                        value: (unbooked_minutes / 60),
                                        title: `Unbooked \u2022 ${((this.datePipe.transform(start, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(start, 'd') : this.datePipe.transform(start, 'd MMMM')) + (end.getDate() != start.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))}`,
                                        tooltip_value: `${this.formatMinutesToHoursAndMinutes(unbooked_minutes)} (${100 - percentage}%)`
                                    }
                                ]
                            });
                        }
                        break;
                }

                this.informationLoaded.occupancy = true;
            });
        });
	}

    formatMinutesToHoursAndMinutes(minutes, full_format: boolean = false) {
        if(minutes == 0 && full_format) return '0h 0min';
        if(minutes == 0 && !full_format) return '0min';
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
	  
        if(full_format) {
            return `${hours}h ${remainingMinutes}min`.trim();
        } else {
            const hoursText = hours > 0 ? `${hours}h` : '';
            const minutesText = remainingMinutes > 0 ? `${remainingMinutes}min` : '';
            return `${hoursText} ${minutesText}`.trim();
        }
	}

    private initializeApptsDuration() {
        this.http.post('/api/dashboard/appointments_duration', this.req).subscribe((resp:any) => {
            this.appointments_by_duration = [
                {
                    name: 'Short',
                    extra_name: '(0-15 min)',
                    value: resp.find((element: any) => element._id === 'Short')?.count || 0,
                }, {
                    name: 'Medium',
                    extra_name: '(16-30 min)',
                    value: resp.find((element: any) => element._id === 'Medium')?.count || 0,
                }, {
                    name: 'Long',
                    extra_name: '(31-60 min)',
                    value: resp.find((element: any) => element._id === 'Long')?.count || 0,
                }, {
                    name: 'Extended',
                    extra_name: '(61-90 min)',
                    value: resp.find((element: any) => element._id === 'Extended')?.count || 0,
                }, {
                    name: 'Extra-Long',
                    extra_name: '(91+ min)',
                    value: resp.find((element: any) => element._id === 'Extra-Long')?.count || 0,
                }
            ];

		    this.informationLoaded.apptsDuration = true;
        });
	}

    private initializeApptRows() {
        this.http.post('/api/dashboard/get_appointments', this.req).subscribe((resp:any) => {
            this.apptRows = resp; 
            this.informationLoaded.apptRows = true;
        });
	}

    private initializeDistribution() {
        this.http.post('/api/dashboard/distribution', this.req).subscribe((resp:any) => {
            this.distribution = {
                total: resp.total || 0,
                items: [
                    {
                        name: 'Services',
                        value: resp.services || 0,
                    }, {
                        name: 'Products',
                        value: resp.products || 0,
                    }, {
                        name: 'Tips',
                        value: resp.tips || 0,
                    }
                ]
            };
            this.informationLoaded.distribution = true;
        });
	}

    private initializeRevenueBarChart() {
        this.http.post('/api/dashboard/revenue_bar_chart', this.req).subscribe((resp:any) => {
            var buf = []

            switch(this.interval) {
                case 'day':
                    for(let i = 0; i < 24; i++) {                        
                        let d = new Date(this.selector.start);
                        d.setHours(i, 0, 0);
                        
                        buf.push({
                            name: d.toISOString(),
                            value: resp.find((element: any) => element._id === i)?.value || 0,
                        });
                    }
                    break;
                case 'week':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                        buf.push({
						    name: d.toISOString(),
                            value: resp.find((element: any) => element._id === d.getDate())?.value || 0,
                        });
                    }
                    break;
                case 'month':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                        buf.push({
						    name: d.toISOString(),
                            value: resp.find((element: any) => element._id === d.getDate())?.value || 0,
                        });
                    }
                    break;
                case 'year':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {                      
                        buf.push({
						    name: d.toISOString(),
                            value: resp.find((element: any) => element._id === (d.getMonth() + 1))?.value || 0,
                        });
                    }
                    break;
                case 'quarter':
                    for (let start = new Date(this.selector.start); start <= new Date(this.selector.end); start.setDate(start.getDate() + 7)) {
                        let end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
                        if(end > this.selector.end) end = new Date(this.selector.end);

                        var value = 0;

                        for (let d = new Date(start); d <= new Date(end); d.setDate(d.getDate() + 1)) {                            
                            value += resp.find((element: any) => element._id === this.datePipe.transform(d, 'M/d/yyyy'))?.value || 0;
                        }
                        
                        buf.push({
						    name: start.toISOString() + ' - ' + end.toISOString(),
                            value: value,
                        });
                    }
                    break;
            }

            this.revenueBarChart = buf;

		    this.informationLoaded.revenueBarChart = true;
        });
	}

    private initializeClientsAge() {
        this.http.post('/api/dashboard/clients_age', this.req).subscribe((resp:any) => {
            this.clients_by_age = {
                total: resp.total || 0,
                items: [
                    {
                        name: 'Under 18',
                        value: resp.age?.find(age => age._id === 0)?.count || 0,
                    }, {
                        name: '18-34',
                        value: resp.age?.find(age => age._id === 18)?.count || 0,
                    }, {
                        name: '35-49',
                        value: resp.age?.find(age => age._id === 35)?.count || 0,
                    }, {
                        name: '50-64',
                        value: resp.age?.find(age => age._id === 50)?.count || 0,
                    }, {
                        name: '65 and Over',
                        value: resp.age?.find(age => age._id === 65)?.count || 0,
                    }
                ]
            };

            this.informationLoaded.clientsAge = true;
        });
	}

    private initializeClientsLineChart() {
        this.http.post('/api/dashboard/clients_line_chart', this.req).subscribe((resp:any) => {
            this.clientsMaxYValue = 0;
            this.clientsLineChart = [
                {
                    "name": "Clients",
                    "series": []
                }
            ];
            
            switch(this.interval) {
                case 'day':
                    for(let i = 0; i < 24; i++) {
                        const value = resp.find((element: any) => element._id === i)?.count || 0;
                        
                        let d = new Date(this.selector.start);
                        d.setHours(i, 0, 0);

                        if(value > this.clientsMaxYValue) this.clientsMaxYValue = value;
                        
                        this.clientsLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortTime'),
                            value: value,
                            title: this.datePipe.transform(d, 'EEEE d MMMM, h:mm a')
                        });
                    }
                    break;
                case 'week':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                        const value = resp.find((element: any) => element._id === this.datePipe.transform(d, 'M/d/yyyy'))?.count || 0;

                        if(value > this.clientsMaxYValue) this.clientsMaxYValue = value;

                        this.clientsLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortDate'),
                            value: value,
                            title: this.datePipe.transform(d, 'EEEE d MMMM')
                        });
                    }
                    break;
                case 'month':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                        const value = resp.find((element: any) => element._id === d.getDate())?.count || 0;

                        if(value > this.clientsMaxYValue) this.clientsMaxYValue = value;

                        this.clientsLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortDate'),
                            value: value,
                            title: this.datePipe.transform(d, 'EEEE d MMMM')
                        });
                    }
                    break;
                case 'year':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
                        const value = resp.find((element: any) => element._id === (d.getMonth() + 1))?.count || 0;

                        if(value > this.clientsMaxYValue) this.clientsMaxYValue = value;
                        
                        this.clientsLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'MMM'),
                            value: value,
                            title: this.datePipe.transform(d, 'MMMM')
                        });
                    }
                    break;
                case 'quarter':
                    for (let start = new Date(this.selector.start); start <= new Date(this.selector.end); start.setDate(start.getDate() + 7)) {
                        let end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
                        if(end > this.selector.end) end = new Date(this.selector.end);

                        var clients = [];

                        for (let d = new Date(start); d <= new Date(end); d.setDate(d.getDate() + 1)) { 
                            clients = [...new Set([...clients, ...resp.find((element: any) => element._id.day === d.getDate() && element._id.month === (d.getMonth() + 1))?.clients || []])];                           
                        }
                        
                        if(clients.length > this.clientsMaxYValue) this.clientsMaxYValue = clients.length;
                        
                        this.clientsLineChart[0].series.push({
                            name: this.datePipe.transform(start, 'shortDate'),
                            value: clients.length,
                            title: ((this.datePipe.transform(start, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(start, 'd') : this.datePipe.transform(start, 'd MMMM')) + (end.getDate() != start.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))
                        });
                    }
                    break;
            }

		    this.informationLoaded.clientsLineChart = true;
        });
	}

    private initializeNoShowsClients() {
        this.http.post('/api/dashboard/no-shows_clients', this.req).subscribe((resp:any) => {
            this.noShowsRows = resp;
            this.informationLoaded.noShowsClients = true;
        });
	}

    private initializeStaffOccupancy() {
		this.occupancyRows = [];

        this.http.post('/api/dashboard/appointments_staff_minutes', this.req).subscribe((resp:any) => {
            const staff_appointments = resp;

            this.mongo.on('user location closed', () => {
                for(let location of this.loc.locations) {
                    if(!this.location || (this.location == location._id)) {

                        const closeds = this.getClosedForLocation(location, this.selector.start);
                        
                        for(let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                            d.setHours(0, 0, 0, 0);
                            let day = this.datePipe.transform(new Date(d), 'EEEE');
                            let format = this.datePipe.transform(new Date(d), 'M/d/yyyy');
                        
                            if(location.data.business_hours?.[day]?.length) {
                                
                                let closed = false;
                                for (let i = closeds.length-1; i >= 0; i--){
                                    if(
                                        new Date(closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
                                        new Date(closeds[i].end?.singleDate?.formatted) >= new Date(d)
                                    ) {                                                  					
                                        closed = true;
                                        break;
                                    }
                                } 
            
                                if(!closed) {	
                                    
                                    for(let staff of this.us.allowed_appointments) {
            
                                        if(staff.location.find(l => l == location._id)) {
                                            let working_minutes = 0;
            
                                            if(staff.data.working_hours?.[format]?.hours?.length && !staff.data.working_hours?.[format]?.vacation) {
                                                working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours[format].hours);
                                            } else if(staff.data.working_hours?.default?.[day]?.length && !staff.data.working_hours?.[format]?.vacation) {
                                                working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours.default[day]);
                                            }

											if(!this.occupancyRows.find(r => r.staff._id === staff._id)) {
												const visit_minutes = staff_appointments.find((s: any) => s._id === staff._id)?.value || 0;
                                                this.occupancyRows.push({
                                                    staff: staff,
                                                    staff_name: staff.name,
                                                    working_minutes: working_minutes,
                                                    visit_minutes: visit_minutes,
                                                    occupancy: working_minutes > 0 && visit_minutes ? Math.round(visit_minutes * 100 / working_minutes) : 0
                                                });
                                            } else {
												const index = this.occupancyRows.findIndex(r => r.staff._id === staff._id);
                                                this.occupancyRows[index].working_minutes += working_minutes;
                                                this.occupancyRows[index].occupancy = this.occupancyRows[index].working_minutes > 0 && this.occupancyRows[index].visit_minutes ? Math.round(this.occupancyRows[index].visit_minutes * 100 / this.occupancyRows[index].working_minutes) : 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        
                this.informationLoaded.staffOccupancy = true;
            });
        });
	}

    private initializeTipLineChart() {
        this.http.post('/api/dashboard/tip_line_chart', this.req).subscribe((resp:any) => {
            this.tipMaxYValue = 0;
            this.tipLineChart = [
                {
                    "name": "Total",
                    "series": []
                }, {
                    "name": "Average",
                    "series": []
                }
            ];

            switch(this.interval) {
                case 'day':
                    for(let i = 0; i < 24; i++) {
                        const sum = resp.find((element: any) => element._id === i)?.sum || 0;
                        const count = resp.find((element: any) => element._id === i)?.count || 0;
                        const avg = count ? (sum / count) : 0;
                        
                        let d = new Date(this.selector.start);
                        d.setHours(i, 0, 0);

                        if(sum > this.tipMaxYValue) this.tipMaxYValue = sum;
                        
                        this.tipLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortTime'),
                            value: sum,
                            title: this.datePipe.transform(d, 'EEEE d MMMM, h:mm a')
                        });
                        this.tipLineChart[1].series.push({
                            name: this.datePipe.transform(d, 'shortTime'),
                            value: avg,
                            title: this.datePipe.transform(d, 'EEEE d MMMM, h:mm a')
                        });
                    }
                    break;
                case 'week':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                        const sum = resp.find((element: any) => element._id === d.getDate())?.sum || 0;
                        const count = resp.find((element: any) => element._id === d.getDate())?.count || 0;
                        const avg = count ? (sum / count) : 0;

                        if(sum > this.tipMaxYValue) this.tipMaxYValue = sum;
                        
                        this.tipLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortDate'),
                            value: sum,
                            title: this.datePipe.transform(d, 'EEEE d MMMM')
                        });
                        this.tipLineChart[1].series.push({
                            name: this.datePipe.transform(d, 'shortDate'),
                            value: avg,
                            title: this.datePipe.transform(d, 'EEEE d MMMM')
                        });
                    }
                    break;
                case 'month':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
                        const sum = resp.find((element: any) => element._id === d.getDate())?.sum || 0;
                        const count = resp.find((element: any) => element._id === d.getDate())?.count || 0;
                        const avg = count ? (sum / count) : 0;

                        if(sum > this.tipMaxYValue) this.tipMaxYValue = sum;
                        
                        this.tipLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'shortDate'),
                            value: sum,
                            title: this.datePipe.transform(d, 'EEEE d MMMM')
                        });
                        this.tipLineChart[1].series.push({
                            name: this.datePipe.transform(d, 'shortDate'),
                            value: avg,
                            title: this.datePipe.transform(d, 'EEEE d MMMM')
                        });
                    }
                    break;
                case 'year':
                    for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
                        const sum = resp.find((element: any) => element._id === (d.getMonth() + 1))?.sum || 0;
                        const count = resp.find((element: any) => element._id === (d.getMonth() + 1))?.count || 0;
                        const avg = count ? (sum / count) : 0;

                        if(sum > this.tipMaxYValue) this.tipMaxYValue = sum;
                        
                        this.tipLineChart[0].series.push({
                            name: this.datePipe.transform(d, 'MMM'),
                            value: sum,
                            title: this.datePipe.transform(d, 'MMMM')
                        });
                        this.tipLineChart[1].series.push({
                            name: this.datePipe.transform(d, 'MMM'),
                            value: avg,
                            title: this.datePipe.transform(d, 'MMMM')
                        });
                    }
                    break;
                case 'quarter':
                    for (let start = new Date(this.selector.start); start <= new Date(this.selector.end); start.setDate(start.getDate() + 7)) {
                        let end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
                        if(end > this.selector.end) end = new Date(this.selector.end);

                        var sum = 0, count = 0;

                        for (let d = new Date(start); d <= new Date(end); d.setDate(d.getDate() + 1)) { 
                            sum += resp.find((element: any) => element._id === this.datePipe.transform(d, 'M/d/yyyy'))?.sum || 0;
                            count += resp.find((element: any) => element._id === this.datePipe.transform(d, 'M/d/yyyy'))?.count || 0;
                        }
                        
                        const avg = count ? (sum / count) : 0;                    
                        if(sum > this.tipMaxYValue) this.tipMaxYValue = sum;
                        
                        this.tipLineChart[0].series.push({
                            name: this.datePipe.transform(start, 'shortDate'),
                            value: sum,
                            title: ((this.datePipe.transform(start, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(start, 'd') : this.datePipe.transform(start, 'd MMMM')) + (end.getDate() != start.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))
                        });
                        this.tipLineChart[1].series.push({
                            name: this.datePipe.transform(start, 'shortDate'),
                            value: avg,
                            title: ((this.datePipe.transform(start, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(start, 'd') : this.datePipe.transform(start, 'd MMMM')) + (end.getDate() != start.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))
                        });
                    }
                    break;
            }

            this.informationLoaded.tipLineChart = true;
        });
	}

    private initializeTips() {
        const previous = this.getPreviousSelector();

        this.http.post('/api/dashboard/tips', { ...this.req, previous: this.getRequestSelector(previous) }).subscribe((resp:any) => {
            let currentSum = resp.current.sum || 0;
            let currentAvg = resp.current.count ? ( resp.current.sum / resp.current.count ) : 0;
            let previousSum = resp.previous.sum || 0;
            let previousAvg = resp.previous.count ? ( resp.previous.sum / resp.previous.count ) : 0;

            let differenceSum = '0%';
            if (previousSum) {
                const percentageChange = ((currentSum - previousSum) / previousSum) * 100;
                differenceSum = (percentageChange > 0 ? '+' : '') + (Math.round(percentageChange * 100) / 100).toString() + '%';
            } else if(currentSum){
                differenceSum = '+100%';
            } else {
                differenceSum = '0%';
            }

            let differenceAvg = '0%';
            if (previousAvg) {
                const percentageChange = ((currentAvg - previousAvg) / previousAvg) * 100;
                differenceAvg = (percentageChange > 0 ? '+' : '') + (Math.round(percentageChange * 100) / 100).toString() + '%';
            } else if(currentAvg){
                differenceAvg = '+100%';
            } else {
                differenceAvg = '0%';
            }

            this.totalTips = {
                current: this.addMoneyAbbreviation(currentSum),
                difference: differenceSum
            };
        
            this.averageTip = {
                current: this.addMoneyAbbreviation(currentAvg),
                difference: differenceAvg
            };

            this.informationLoaded.tips = true;
        });
	}

    private initializeRevenueAndTip() {
        this.http.post('/api/dashboard/revenue_and_tip', this.req).subscribe((resp:any) => {
            this.revenueTipRows = resp;
		    this.informationLoaded.revenueTip = true;
        });
	}

    private getClosedForLocation(location, date) {
        const dateHolidays = new DateHolidays();
        switch(location.country) {
            case 'Canada':
                var shortcode_country = 'CA';	
                break;
            case 'USA':
                var shortcode_country = 'US';	
                break;
        }
        dateHolidays.init(shortcode_country);
        dateHolidays.setTimezone(location.timezone);
        const holidays = dateHolidays.getHolidays(new Date(date).getFullYear());
        const closeds = this.cls.closeds.filter((closed: any) => closed.locations.find(l => l == location._id)).map((c: any) => {
            const closed = JSON.parse(JSON.stringify(c))
            if(closed.holiday) {
                let holiday = holidays.find((holiday: any) => holiday.name === closed.holiday.name);
                let substitute = holidays.find((holiday: any) => { return holiday.name.indexOf(closed.holiday.name) != -1 && holiday.name.indexOf('(substitute day)') != -1 });
                if( (holiday && !closed.substitute) || (holiday && closed.substitute && !substitute) ) {
                    closed.start = this.cls.dateFormatting(holiday.start, closed.holiday.timezone);
                    const end = new Date(holiday.end);
                    end.setDate(end.getDate() - 1);
                    closed.end = this.cls.dateFormatting(end, closed.holiday.timezone);
                } else if( closed.substitute && substitute ) {
                    closed.start = this.cls.dateFormatting(substitute.start, closed.holiday.timezone);
                    const end = new Date(substitute.end);
                    end.setDate(end.getDate() - 1);
                    closed.end = this.cls.dateFormatting(end, closed.holiday.timezone);
                }
            }
            return closed;
        });
        
        return closeds;
    }
}
