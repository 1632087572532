import { Component, HostListener, OnInit, Input, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { UserService, LoaderService } from '@services';
import { DashboardService } from '../dashboard.service'

@Component({
	selector: 'app-dashboard-appointments',
	templateUrl: './dashboard-appointments.component.html',
	styleUrls: ['./dashboard-appointments.component.scss', '../dashboard.component.scss']
})
export class DashboardAppointmentsComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public windowWidth: number;

	public yAxisAppts = this.yAxisApptsFormatting.bind(this);

	public yAxisOccupancy = this.yAxisOccupancyFormatting.bind(this);

	public apptColumns = [
		{
			title: 'clients',
			field: 'client_name'
		}, {
			title: 'date',
			field: 'date'
		}, {
			title: 'duration',
			field: 'duration'
		}, {
			title: 'services',
			field: 'service_name'
		}, {
			title: 'price',
			field: 'service_price'
		}, {
			title: 'status',
			field: 'status'
		}, 
	];

	public afterViewInit: boolean = false;

	private informationLoaded: any = {
	};

	constructor(
		public us: UserService, 
		private loader: LoaderService,
		private eref: ElementRef,
		public dashboard: DashboardService
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
		
		this.dashboard.tabAppointments();

		this.windowWidth = window.innerWidth;
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					setTimeout(() => {
						this.dashboard.resetAllData();
						this.dashboard.tabLoaded('appointments', () => {
							this.refreshCharts();
							this.loader.remove();
						});
					}, 10);
				}
			}, 1);
		}
	}

	ngAfterViewInit() {
		this.afterViewInit = true;
		this.dashboard.tabLoaded('appointments', () => {
			this.refreshCharts();
			this.loader.remove();
		});
	}
	
	refresh() {
		let waitForInformationLoading = setInterval(() => {
			if (Object.values(this.informationLoaded).every(value => value === true)) {
				clearInterval(waitForInformationLoading);
				this.refreshCharts();
				if(this.afterViewInit) this.loader.remove();
			}
		}, 1);
	}

	refreshCharts() {
		document.querySelectorAll('.circular-chart .circle').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 10);
		});
	}
	
	yAxisApptsFormatting(value) {
		return value;
	}

	yAxisOccupancyFormatting(value) {
		return this.dashboard.formatMinutesToHoursAndMinutes(value * 60);
	}

	@HostListener('window:resize')
	onWindowResize() {
		this.windowWidth = window.innerWidth;
	}
}