<div class="appoint-popup" *ngIf="appointment.selected" (click)="$event.stopPropagation();" (clickOutside)="close()">
    <div class="appoint-popup__nav">
        <span class="material-icons" (click)="edit_appointment(appointment)">edit</span>
        <span class="material-icons" (click)="aps.delete(appointment); close()">delete_outline</span>
        <span class="material-icons" (click)="appointment.showPopup = !appointment.showPopup;">more_vert</span>
        <span class="material-icons" (click)="close()">close</span>
    </div>
    <div class="popup" *ngIf="appointment.showPopup">
        <div class="popup-block" (click)="check(appointment)"><span>Checkout</span></div>
        <div class="popup-block" *ngIf="appointment.status != 'Confirmed'" (click)="aps.confirm(appointment);close()"><span>Send Confirmation</span></div>
        <div class="popup-block" *ngIf="us._users[appointment.client]._id == us._id && appointment.status != 'Canceled'&&appointment.status != 'Completed'&&appointment.status != 'Arrived'" (click)="aps.mark(appointment);close()"><span>Mark Arrived</span></div>
        <div class="popup-block" (click)="reschedule(appointment); close()"><span>Reschedule</span></div>
        <div class="popup-block" *ngIf="!us.role&&appointment._id&&appointment.client.includes(us._id)&&if_past" (click)="note(appointment); close()"><span>Send Note</span></div>
        <div (click)="edit_appointment(appointment);close()" class="popup-block"><span>Modify</span></div>
        <div class="popup-block" (click)="rebook(appointment); close()"><span>Rebook</span></div>
        <div class="popup-block" *ngIf="(us._users[appointment.client]._id == us._id || us._users[appointment.user]._id == us._id) && appointment.status != 'Canceled'&&appointment.status != 'Confirmed'" (click)="aps.cancel(appointment);close()"><span>Early Cancel</span></div>
    </div>
    <div>
        <span class="appoint-popup__color" [style.background]="ss._services[appointment.service]?.color"></span>

        <div class="appoint-popup__title">{{ss._services[appointment.service]?.name}}</div>
        <div class="appoint-popup__time">
            <span>{{appointment.day.singleDate.formatted|date: 'EEEE, d MMMM'}}</span>
            <span class="ml10 mr10">|</span>
            <span>{{appointment.start}} - {{appointment.end}}</span>
        </div>

        <div class="ai-c ml10">
            <div class="cal-table__avatar as-s" [class._letter]="!us._users[appointment.client].avatarUrl">
                <picture [user]="us._users[appointment.client]"></picture>
            </div>
            <div class="appoint-popup__name">{{us._users[appointment.client].name}}</div>
            <div *ngIf="us._users[appointment.client]?.deleted" class="appoint-popup__name"> (Deleted User)</div>
        </div>
    </div>

</div>
