import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UserService, LocationService, HashService, LoaderService, CheckoutService } from '@services';
import { DashboardService } from './dashboard.service'

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
	public tab: string = 'overview';

	public tabs: any = [
		'overview',
		'appointments',
		'sales',
		'clients',
		'staff'
	];

	constructor(
		public us: UserService, 
		public loc: LocationService,
		public hash: HashService,
		private loader: LoaderService,
		private eref: ElementRef,
		public cs: CheckoutService,
		public dashboard: DashboardService
	) {
		this.us.headerTitle = 'dashboard';
		this.us.headerSubtitle = '';
		this.hash.on('tab', tab => {
			if(tab) this.hash.set('tab', tab);
			this.tab = tab || 'overview'
		});

		this.dashboard.getDataFromQueryParams();
	}

	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}

	ngOnDestroy() {
		this.cs.clear();		
	}
}