<!-- === Clients Notes ===  -->
<div class="table-container tablet-col3 mobile-col3">
	<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
		<thead>
			<tr>
				<th scope="col" class="sort" (click)="sort('nameAp')">
					<div class="ai-c">
						<span>Client</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'nameAp'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'nameAp'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('date.singleDate.epoc')">
					<div class="ai-c">
						<span>Day</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'date.singleDate.epoc'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'date.singleDate.epoc'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('servicesNote')">
					<div class="ai-c">
						<span>Appointment</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'servicesNote'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'servicesNote'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('created_date.singleDate.epoc')">
					<div class="ai-c">
						<span>Added</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'created_date.singleDate.epoc'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'created_date.singleDate.epoc'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col">Note</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let note of nt.client_notes| notesFilter: services: date: nt.now| searchByClient: searching: '2'| sort: sort_type">
				<td data-label="Client" class="ovf-hidden" [tooltip]="us._users[note.client]?.name" tooltipClass="tableTooltip" tooltipShowDelay="200">
					<div class="table__user">
						<div class="table__user__img" [class._letter]="!us._users[note.client]?.avatarUrl">
							<picture [user]="us._users[note.client]"></picture>
						</div>
						<div class="table__user__info">
							<span class="table__user__info-name">{{us._users[note.client]?.firstName}} <wbr> {{us._users[note.client]?.lastName}}</span>
							<div *ngIf="us._users[note.client]?.deleted" class="cl-table-clients__status">(Deleted User)</div>
						</div>
					</div>
				</td>
				<td data-label="Day" class="text-elipsis" [tooltip]="note.date?.singleDate?.jsDate | date: 'EE, MMM d, y'" tooltipClass="tableTooltip" tooltipShowDelay="200">{{note.date?.singleDate?.formatted || ''}}</td>
				<td data-label="Appointment" class="text-elipsis" [tooltip]="getTooltipAppointment(note)" tooltipClass="tableTooltip" tooltipShowDelay="200">{{ss._services[note.appointment?.service]?.name}}</td>
				<td data-label="Added" class="text-elipsis" [tooltip]="note.created_date?.singleDate?.jsDate | date: 'EE, MMM d, y, h:mm a'" tooltipClass="tableTooltip" tooltipShowDelay="200">{{note.created_date?.singleDate?.formatted || ''}}</td>
				<td data-label="Note" style="grid-column: span 2;">
					<div class="note _limit" #Note [innerHtml]="note.description | note" [class._more]="Note.scrollHeight > Note.clientHeight" (click)="Note.scrollHeight > Note.clientHeight ? Note.classList.add('_all') : !Note.classList.contains('_all') && Note.classList.remove('_all')"></div>
					<button class="note-btn" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')">{{Note.scrollHeight > Note.clientHeight ? 'More' : 'Less'}}</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>
