import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'notesFilter'
})
export class NotesFilterPipe implements PipeTransform {
	transform(notes, services=[], date, now) {
		if(!notes) return [];
		if(!services.length && (typeof date == 'undefined'||!date)) return notes;
		notes = notes.slice();
		if(services.length) {
			for (let i = notes.length-1; i >= 0; i--){
				let bool = '0';
				for (let j = services.length-1; j >= 0; j--){
					if(notes[i].appointment?.service == services[j]) {
						bool = '1';
					}
				}
				if(bool == '0') notes.splice(i, 1);
			}
		}
		if((typeof date != 'undefined'&&date)) {
			for (let i = notes.length-1; i >= 0; i--){
				let bool = '0';
				if(date?.singleDate?.formatted == notes[i].date?.singleDate?.formatted) {
					bool = '1';
				}
				if(bool == '0') notes.splice(i, 1);
			}
		}
		return notes;
	}

}
