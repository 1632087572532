import { Component, OnInit, Input, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { LoaderService, UserService } from '@services';
import { MongoService } from 'wacom';
import { DashboardService } from '../dashboard.service'

@Component({
	selector: 'app-dashboard-clients',
	templateUrl: './dashboard-clients.component.html',
	styleUrls: ['./dashboard-clients.component.scss', '../dashboard.component.scss']
})
export class DashboardClientsComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public yAxisClients = this.yAxisClientsFormatting.bind(this);

	public topClientsColumns: any = [{ title: 'client', field: 'client_name' }, 'appointments', 'revenue', { title: 'total tips', field: 'tips' }];

	public noShowsColumns: any = [{ title: 'client', field: 'client_name' }, { title: 'no-shows', field: 'no_shows' }, { title: 'no-shows value', field: 'no_shows_value' }, 'appointments', 'revenue'];

	public afterViewInit: boolean = false;

	constructor(
		public us: UserService, 
		private mongo: MongoService,
		private loader: LoaderService,
		private eref: ElementRef,
		public dashboard: DashboardService
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		this.dashboard.tabClients();
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					setTimeout(() => {
						this.dashboard.resetAllData();
						this.dashboard.tabLoaded('clients', () => {
							this.loader.remove();
						});
					}, 10);
				}
			}, 1);
		}
	}

	ngAfterViewInit() {
		this.mongo.on('user', () => {
			this.afterViewInit = true;
			this.dashboard.tabLoaded('clients', () => {
				this.loader.remove();
			});
		});
	}

	yAxisClientsFormatting(value) {
		return value;
	}
}