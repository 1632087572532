<div class="dashboard" [class.day]="interval == 'day'">
	<div class="dashboard-card" style="grid-area: chart-appts">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Appointments
			</div>
		</div>
		<div class="dashboard-card__content">
			<div class="chart-container">
				<div class="chart">
					<ngx-charts-line-chart 
						[scheme]="{domain: ['#6AC4B3']}" 
						[results]="dashboard.apptsLineChart" 
						[xAxis]="true"
						[yAxis]="true"
						[roundDomains]="true"
						[yAxisTickFormatting]="yAxisAppts"
						[yAxisTicks]="dashboard.apptsMaxYValue < 5 ? [0, 1, 2, 3, 4, 5] : (dashboard.apptsMaxYValue < 10 ? [0, 2, 4, 6, 8, 10] : '')"
						[yScaleMax]="dashboard.apptsMaxYValue < 5 ? 5 : (dashboard.apptsMaxYValue < 10 ? 10 : '')"
						[yScaleMin]="dashboard.apptsMaxYValue < 10 ? 0 : ''"
						>
						<ng-template #tooltipTemplate let-model="model">
							<div class="tooltip">
								<div class="tooltip__title">{{model?.title}}</div>
								<div class="tooltip__value">{{model?.value}}</div>
							</div>
						</ng-template>
						
						<ng-template #seriesTooltipTemplate let-model="model">
							<div class="tooltip">
								<div class="tooltip__title">{{model[0]?.title}}</div>
								<div class="tooltip__value">{{model[0]?.value}}</div>
							</div>
						</ng-template>
					</ngx-charts-line-chart>
				</div>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: status">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Status
			</div>
		</div>
		<div class="dashboard-card__content">
			<donat-chart [total]="dashboard.appts" [items]="dashboard.appointments_by_status"></donat-chart>
		</div>
	</div>
	<div *ngIf="interval == 'day'" class="dashboard-card" style="grid-area: occupancy-day">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Occupancy
			</div>
		</div>
		<div class="dashboard-card__content occupancy-day">
			<div class="legend">
				<div class="legend-item">
					<div class="legend-item__color" style="background: #6AC4B3"></div>
					<span class="legend-item__name">Booked hours <span class="legend-item__value">{{dashboard.formatMinutesToHoursAndMinutes(dashboard.occupancy.booked_minutes)}}</span></span>
				</div>
				<div class="legend-item">
					<div class="legend-item__color" style="background: #EEEEEE"></div>
					<span class="legend-item__name">Working hours <span class="legend-item__value">{{dashboard.formatMinutesToHoursAndMinutes(dashboard.occupancy.working_minutes)}}</span></span>
				</div>
			</div>
			<ng-template [ngTemplateOutlet]="circularChartLarge" [ngTemplateOutletContext]="{percentage: dashboard.occupancy.percentage}"></ng-template>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: duration">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Duration
			</div>
		</div>
		<div class="dashboard-card__content">
			<donat-chart [total]="dashboard.appts" [items]="dashboard.appointments_by_duration" [legendPosition]="windowWidth <= 1440 && windowWidth >= 768 && interval == 'day' ? 'left' : 'top'"></donat-chart>
		</div>
	</div>
	<div *ngIf="interval != 'day'" class="dashboard-card" style="grid-area: occupancy">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Occupancy
			</div>
		</div>
		<div class="dashboard-card__content">
			<div class="chart-container">
				<div class="chart occupancy-chart">
					<ngx-charts-bar-vertical-stacked
						[scheme]="{domain: ['#6AC4B3', '#EEEEEE']}" 
						[results]="dashboard.occupancyBarChart"
						[xAxis]="true"
						[yAxis]="true"
						[legend]="true"
						[showGridLines]="false"
						[legendPosition]="'below'"
						[legendTitle]="''"
						[barPadding]="10"
						[yAxisTickFormatting]="yAxisOccupancy"
						[yAxisTicks]="dashboard.occupancy.working_minutes == 0 ? [0, 2, 4, 6, 8, 10] : ''"
						[yScaleMax]="dashboard.occupancy.working_minutes == 0 ? 10 : ''"
						>
						<ng-template #tooltipTemplate let-model="model">
							<div class="tooltip">
								<div class="tooltip__title">{{model?.title}}</div>
								<div class="tooltip__value">{{model?.tooltip_value}}</div>
							</div>
						</ng-template>
					</ngx-charts-bar-vertical-stacked>
				</div>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: table">
		<div class="dashboard-card__content">
			<app-table [columns]="apptColumns" [rows]="dashboard.apptRows">
				<ng-template sort cell="client_name" let-element>
					<div class="client">
						<div class="client__img">
							<picture [user]="us._users[element?.client]"></picture>
						</div>
						<div class="client__name">
							{{element?.client_name}}
						</div>
					</div>
				</ng-template>
				<ng-template sort cell="date" let-element>
					{{element?.date|date:'short'}}
				</ng-template>
				<ng-template sort cell="duration" let-element>
					{{dashboard.formatMinutesToHoursAndMinutes(element?.duration)}}
				</ng-template>
				<ng-template sort cell="service_name" let-element>
					{{element?.service_name}}
				</ng-template>
				<ng-template sort cell="service_price" let-element>
					${{element?.service_price}}
				</ng-template>
				<ng-template sort cell="status" let-element>
					{{element?.status}}
				</ng-template>
			</app-table>
		</div>
	</div>
</div>

<!-- 
SVG circular chart 225 x 225
-->
<ng-template #circularChartLarge let-percentage="percentage">
	<svg width="225" height="225" viewBox="0 0 50 50" class="circular-chart">
		<g filter="url(#filter0_d_521_907)">
			<path fill="none" stroke="#EEEEEE" stroke-width="3.8" d="M25 9.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>	
		</g>
		<circle cx="25" cy="25" r="13.8" fill="white" />
        <text x="25" y="25" dy="0.35em" text-anchor="middle" font-size="5.5px" font-family="Poppins, sans-serif" font-weight="500" fill="#343434" >{{percentage}}%</text>
		<path *ngIf="percentage" class="circle _animation" fill="none" stroke="#6AC4B3" stroke-width="3.8" stroke-linecap="round" [attr.stroke-dasharray]="percentage + ', 100'" d="M25 9.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
		<defs>
			<filter id="filter0_d_521_907" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix"/>
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 24.6 0" result="hardAlpha"/>
				<feMorphology radius="2.3" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_521_907"/>
				<feOffset/>
				<feComposite in2="hardAlpha" operator="out"/>
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"/>
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_521_907"/>
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_521_907" result="shape"/>
			</filter>
		</defs>
	</svg>
</ng-template>