import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentService, ProductService, ServiceService, TaxService, UserService } from '@services';
import { ModalService } from 'wacom';

@Component({
  selector: 'appointment-popup',
  templateUrl: './appointment-popup.component.html',
  styleUrls: ['./appointment-popup.component.scss'],
  inputs: ['appointment', 'model']
})
export class AppointmentPopupComponent implements OnInit {
	public appointment:any = {};
	public model;
	constructor(public modalW: ModalService,
		public ss: ServiceService,
		public us: UserService,
		public aps: AppointmentService,
		public ts: TaxService,
		private router: Router,
		private ps: ProductService) { }

	ngOnInit(): void {
	}
	check(appointment) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.close();
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		let check:any = {tax: 0}
		check.appointment = {id: appointment._id, taxes:  this.ss._services[appointment.service].tax};
		check.products = this.ss._services[appointment.service].product.filter(p => this.ps._products[p]?._id).map(p => {return {id: p, qty: 1, discount: this.ps._products[p]?.discount, taxes: this.ps._products[p]?.tax}});
		this.modalW.show({component: 'checkout', check: check})
	}
	edit_appointment(profile){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.aps.pre_set(profile, this.model);
		this.modalW.show({
			component: 'appointment',
			profile: JSON.parse(JSON.stringify(profile))
		});
	}
	rebook(appointment) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modalW.show({component: 'rebook', appointment: appointment});
	}
	reschedule(appointment) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modalW.show({component: 'reschedule', _id: appointment._id});
	}
	note(appointment) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modalW.show({component: 'note', note: { client: appointment.client, appointment: appointment._id, staff: appointment.user, date: appointment.day }, prefill: true});
	}
	close() {
		this.appointment.selected = false;
		this.appointment.showPopup = false
	}
	get if_past() {
		return this.appointment.day.singleDate.epoc < Date.now();
	}
}
