<div class="dashboard">
	<div class="dashboard-card" style="grid-area: chart-clients">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Clients
			</div>
		</div>
		<div class="dashboard-card__content">
			<div class="chart-container">
				<div class="chart">
					<ngx-charts-line-chart
						[scheme]="{domain: ['#6AC4B3']}" 
						[results]="dashboard.clientsLineChart" 
						[xAxis]="true"
						[yAxis]="true"
						[roundDomains]="true"
						[yAxisTickFormatting]="yAxisClients"
						[yAxisTicks]="dashboard.clientsMaxYValue < 5 ? [0, 1, 2, 3, 4, 5] : (dashboard.clientsMaxYValue < 10 ? [0, 2, 4, 6, 8, 10] : '')"
						[yScaleMax]="dashboard.clientsMaxYValue < 5 ? 5 : (dashboard.clientsMaxYValue < 10 ? 10 : '')"
						[yScaleMin]="dashboard.clientsMaxYValue < 10 ? 0 : ''"
						>
						<ng-template #tooltipTemplate let-model="model">
							<div class="tooltip">
								<div class="tooltip__title">{{model?.title}}</div>
								<div class="tooltip__value">{{model?.value}}</div>
							</div>
						</ng-template>
						
						<ng-template #seriesTooltipTemplate let-model="model">
							<div class="tooltip">
								<div class="tooltip__title">{{model[0]?.title}}</div>
								<div class="tooltip__value">{{model[0]?.value}}</div>
							</div>
						</ng-template>
					</ngx-charts-line-chart>
				</div>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: age">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Age Distribution
			</div>
		</div>
		<div class="dashboard-card__content">
			<donat-chart [total]="dashboard.clients_by_age.total" [items]="dashboard.clients_by_age.items"></donat-chart>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: new_clients">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				New Clients
			</div>
		</div>
		<div class="dashboard-card__content">
			<donat-chart [total]="dashboard.new_clients_by_sources.total" [items]="dashboard.new_clients_by_sources.items"></donat-chart>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: top_clients">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Clients by Revenue
			</div>
		</div>
		<div class="dashboard-card__content">
			<app-table [columns]="topClientsColumns" [rows]="dashboard.topClientsRows">
				<ng-template sort cell="client_name" let-element>
					<div class="client">
						<div class="client__img">
							<picture [user]="us._users[element?.client]"></picture>
						</div>
						<div class="client__name">
							{{ element?.client_name }}
						</div>
					</div>
				</ng-template>
				<ng-template sort cell="appointments" let-element>
					{{ element?.appointments }}
				</ng-template>
				<ng-template sort cell="revenue" let-element>
					{{ element?.revenue | currency }}
				</ng-template>
				<ng-template sort cell="tips" let-element>
					{{ element?.tips | currency }}
				</ng-template>
			</app-table>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: no-shows">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Clients no-shows
			</div>
		</div>
		<div class="dashboard-card__content">
			<app-table [columns]="noShowsColumns" [rows]="dashboard.noShowsRows">
				<ng-template sort cell="client_name" let-element>
					<div class="client">
						<div class="client__img">
							<picture [user]="us._users[element.client]"></picture>
						</div>
						<div class="client__name">
							{{ element?.client_name }}
						</div>
					</div>
				</ng-template>
				<ng-template sort cell="no_shows" let-element>
					{{ element?.no_shows }}
				</ng-template>
				<ng-template sort cell="no_shows_value" let-element>
					{{ element?.no_shows_value | currency }}
				</ng-template>
				<ng-template sort cell="appointments" let-element>
					{{ element?.appointments }}
				</ng-template>
				<ng-template sort cell="revenue" let-element>
					{{ element?.revenue | currency }}
				</ng-template>
			</app-table>
		</div>
	</div>
</div>
