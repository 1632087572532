import { Component, HostListener, OnInit, Input, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { UserService, CheckoutService, LoaderService } from '@services';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DashboardService } from '../dashboard.service'

@Component({
	selector: 'app-dashboard-sales',
	templateUrl: './dashboard-sales.component.html',
	styleUrls: ['./dashboard-sales.component.scss', '../dashboard.component.scss']
})
export class DashboardSalesComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public windowWidth: number;

	public checksColumns = [
		{
			title: 'client',
			field: 'clientName'
		}, {
			title: 'date',
			field: 'timestamp'
		}, {
			title: 'total',
			field: 'total'
		}, {
			title: 'tips',
			field: 'tips'
		}, {
			title: 'tax',
			field: 'tax'
		}, {
			title: 'payment form',
			field: 'type'
		}
	];

	public servicesColumns = [
		{
			title: 'service',
			field: 'name'
		}, {
			title: 'bookings',
			field: 'bookings'
		}, {
			title: 'price',
			field: 'minPrice'
		}, {
			title: 'revenue',
			field: 'revenue'
		},
	];

	public yAxisRevenue = this.yAxisRevenueFormatting.bind(this);
	
	public xAxisRevenue = this.xAxisRevenueFormatting.bind(this);
	
	public productsColumns = [
		{
			title: 'product',
			field: 'name'
		}, {
			title: 'items sold',
			field: 'items'
		}, {
			title: 'price',
			field: 'minPrice'
		}, {
			title: 'revenue',
			field: 'revenue'
		},
	];

	public afterViewInit: boolean = false;
	
	constructor(
		public us: UserService, 
		public cs: CheckoutService,
		private datePipe: DatePipe,
		private currencyPipe: CurrencyPipe,
		private loader: LoaderService,
		private eref: ElementRef,
		public dashboard: DashboardService
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		this.dashboard.tabSales();

		this.windowWidth = window.innerWidth;
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					setTimeout(() => {
						this.dashboard.resetAllData();
						this.dashboard.tabLoaded('sales', () => {
							this.loader.remove();
						});
					}, 10);
				}
			}, 1);
		}
	}

	ngAfterViewInit() {
		this.cs.loaded(() => {
			this.afterViewInit = true;
			this.dashboard.tabLoaded('sales', () => {
				this.loader.remove();
			});
		});
	}

	yAxisRevenueFormatting(value) {
		return this.currencyPipe.transform(value);
	}

	xAxisRevenueFormatting(value) {
		switch(this.interval) {
			case 'day':
				return this.datePipe.transform(value, 'shortTime');
			case 'week':
				return this.datePipe.transform(value, 'shortDate');
			case 'month':
				return this.datePipe.transform(value, 'shortDate');
			case 'year':
				return this.datePipe.transform(value, 'MMM');
			case 'quarter':
				return this.datePipe.transform(value.split(' - ')[0], 'shortDate');
		}
	}

	chartTooltipTitleFormatting(title) {
		switch(this.interval) {
			case 'day':
				return this.datePipe.transform(new Date(title), 'EEEE d MMMM, h:mm a');
			case 'week':
				return this.datePipe.transform(new Date(title), 'EEEE d MMMM');
			case 'month':
				return this.datePipe.transform(new Date(title), 'EEEE d MMMM');
			case 'year':
				return this.datePipe.transform(new Date(title), 'MMMM');
			case 'quarter':
				let start = new Date(title.split(' - ')[0]);
				let end = new Date(title.split(' - ')[1]);
				return ((this.datePipe.transform(start, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(start, 'd') : this.datePipe.transform(start, 'd MMMM')) + (end.getDate() != start.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'));
		}
	}

	@HostListener('window:resize')
	onWindowResize() {
		this.windowWidth = window.innerWidth;
	}
}