import { Component, OnInit, Input, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { UserService, LoaderService } from '@services';
import { CurrencyPipe } from '@angular/common';
import { DashboardService } from '../dashboard.service'

@Component({
	selector: 'app-dashboard-staff',
	templateUrl: './dashboard-staff.component.html',
	styleUrls: ['./dashboard-staff.component.scss', '../dashboard.component.scss']
})
export class DashboardStaffComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public occupancyColumns: any = [
		{
			title: 'staff member',
			field: 'staff_name'
		}, {
			title: 'occupancy',
			field: 'occupancy'
		}, {
			title: 'visit time',
			field: 'visit_minutes'
		}, {
			title: 'working hours',
			field: 'working_minutes'
		}
	];

	public yAxisTip = this.yAxisTipFormatting.bind(this);

	public revenueTipColumns: any = [
		{
			title: 'staff member',
			field: 'staff_name'
		}, {
			title: 'appointments',
			field: 'appointments'
		}, {
			title: 'services revenue',
			field: 'revenue'
		}, {
			title: 'total tips',
			field: 'sum_tips'
		}, {
			title: 'average tip',
			field: 'avg_tip'
		}
	];

	public afterViewInit: boolean = false;
	
	constructor(
		public us: UserService, 
		private currencyPipe: CurrencyPipe,
		private loader: LoaderService,
		private eref: ElementRef,
		public dashboard: DashboardService
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		this.dashboard.tabStaff();
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					setTimeout(() => {
						this.dashboard.resetAllData();
						this.dashboard.tabLoaded('staff', () => {
							this.loader.remove();
						});
					}, 10);
				}
			}, 1);
		}
	}

	ngAfterViewInit() {
		this.afterViewInit = true;
		this.dashboard.tabLoaded('staff', () => {
			this.loader.remove();
		});
	}

	yAxisTipFormatting(value) {
		return this.currencyPipe.transform(value);
	}
}