import { Component, OnInit, Input, OnChanges, ElementRef } from '@angular/core';
import { UserService, LoaderService } from '@services';
import { ModalService, MongoService, UiService } from 'wacom';
import { ClientComponent } from 'src/app/modals/client/client.component';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard.service'

@Component({
	selector: 'app-dashboard-overview',
	templateUrl: './dashboard-overview.component.html',
	styleUrls: ['./dashboard-overview.component.scss', '../dashboard.component.scss']
})
export class DashboardOverviewComponent implements OnInit, OnChanges {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public open_service: any = {};
	
	public open_product: any = {};

	public topClientsColumns: any = ['client', 'appointments', 'revenue'];

	public afterViewInit: boolean = false;

	constructor(
		public us: UserService, 
		private mongo: MongoService,
		public ui: UiService,
		public modal: ModalService,
		private router: Router,
		private loader: LoaderService,
		private eref: ElementRef,
		public dashboard: DashboardService
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		this.dashboard.tabOverview();
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					setTimeout(() => {
						this.dashboard.resetAllData();
						this.dashboard.tabLoaded('overview', () => {
							this.refreshCharts();
							this.loader.remove();
						});
					}, 10);
				}
			}, 1);
		}
	}

	ngAfterViewInit() {
		this.mongo.on('user', () => {
			this.afterViewInit = true;
			this.dashboard.tabLoaded('overview', () => {
				this.refreshCharts();
				this.loader.remove();
			});
		});
	}

	refreshCharts() {		
		document.querySelectorAll('.chart .polyline').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 10);
		});
		
		document.querySelectorAll('.chart .line').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 10);
		});

		document.querySelectorAll('.circular-chart .circle').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 10);
		});

		document.querySelectorAll('.horizontal-chart .horizontal-chart-body__stripe').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 5);
		});
	}

	openClient(client) {
		this.router.navigate([], { queryParams: { modal: 'open' }, queryParamsHandling: 'merge', fragment: 'tab=overview' });
		this.modal.show({component: ClientComponent, profile: client});
	}
}