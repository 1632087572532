import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Injectable, AfterViewInit, ElementRef, OnDestroy, OnChanges } from '@angular/core';
import { AppointmentService, UserService, ServiceService, LoaderService } from '@services';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { MongoService } from 'wacom';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = `First page`;
  itemsPerPageLabel = `Items per page:`;
  lastPageLabel =`Last page`;
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${amountPages}`;
  }
}

@Component({
	selector: 'app-service-history',
	templateUrl: './service-history.component.html',
	styleUrls: ['./service-history.component.scss'],
	providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})
export class ServiceHistoryComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
	@Input() searching: string = '';
	@Input() services: any;
	@Input() date: any;
	public config: any = {
		page: 1,
		perPage: 100
	}
	private afterViewInit: boolean = false;
	constructor(public ap: AppointmentService, public us: UserService, public ss: ServiceService, private datePipe: DatePipe, private mongo: MongoService, private loader: LoaderService, private eref: ElementRef) {}
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		if(window.innerWidth <= 1024 && window.innerWidth > 767 && this.config.perPage != 50) this.config.perPage = 50;
		if(window.innerWidth <= 767 && this.config.perPage != 25) return this.config.perPage = 25;

		this.refreshAppointments();
	}
	ngOnChanges() {
		if(this.afterViewInit) this.refreshAppointments();
	}
	ngAfterViewInit() {
		this.afterViewInit = true;
		this.mongo.on('user service', () => {
			this.ap.loaded(() => {
				this.loader.remove();
			});
		});
	}
	ngOnDestroy() {
		this.ap.count = 0;
		const past_arr = [...this.ap.past_arr];
		for (let i = 0; i < past_arr.length; i++) {
			this.mongo.remove('appointment', past_arr[i]);
		}
	}
	public sort_type:any = {};
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
		if(!this.sort_type.direction) this.sort_type.title = 'day.singleDate.jsDate';
		
		this.refreshAppointments();
	}
	timeFormatting(time) {
		var d = new Date();
		d.setHours(time.split(':')[0]);
		d.setMinutes(time.split(':')[1]);
		return this.datePipe.transform(d, 'shortTime');
	}
	handlePageEvent(event) {
		this.config.page = event.pageIndex + 1;
		this.refreshAppointments();
	}
	refreshAppointments() {
		if(!this.loader.isLoaderShowing) this.loader.show({transparent: true, preventClick: true}, this.eref.nativeElement.querySelector('.table-container'));
		this.ap.getServiceHistoryPagination(this.config, this.searching, this.date ? this.date?.singleDate?.formatted : null, this.services, this.sort_type, () => {
			this.loader.remove();
		});
	}
}
