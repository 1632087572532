<div class="dashboard">
	<div class="dashboard-card _mobile" style="grid-area: tip-chart">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Tip Dynamics
			</div>
		</div>
		<div class="dashboard-card__content">
			<div class="chart-container">
				<div class="chart tip-chart">
					<ngx-charts-line-chart 
						[scheme]="{domain: ['#6AC4B3', '#ADB8C6']}" 
						[results]="dashboard.tipLineChart" 
						[xAxis]="true"
						[yAxis]="true"
						[roundDomains]="true"
						[legend]="true"
						[legendTitle]="false"
						[legendPosition]="'below'"
						[yAxisTickFormatting]="yAxisTip"
						[yAxisTicks]="dashboard.tipMaxYValue < 100 ? [0, 50, 100] : dashboard.tipMaxYValue < 500 ? [0, 250, 500] : dashboard.tipMaxYValue < 1000 ? [0, 500, 1000] : ''"
						[yScaleMax]="dashboard.tipMaxYValue < 100 ? 100 : dashboard.tipMaxYValue < 500 ? 500 : dashboard.tipMaxYValue < 1000 ? 1000 : ''"
						[yScaleMin]="0"
						>
						<ng-template #tooltipTemplate let-model="model">
							<div class="tooltip">
								<div class="tooltip__title">{{ model?.series }} {{ '\u2022' }} {{ model?.title }}</div>
								<div class="tooltip__value">{{ model?.value | currency }}</div>
							</div>
						</ng-template>
						
						<ng-template #seriesTooltipTemplate let-model="model">
							<div class="tooltip-series">
								<div class="tooltip-series__title">{{ model[0]?.title }}</div>
								<div class="tooltip-series__item">
									<div class="tooltip-series__item-color" [ngStyle]="{'background-color': model[0]?.color}"></div>
									<div class="tooltip-series__item-value">{{ model[0]?.series }}: {{ model[0]?.value | currency }}</div>
								</div>
								<div class="tooltip-series__item">
									<div class="tooltip-series__item-color" [ngStyle]="{'background-color': model[1]?.color}"></div>
									<div class="tooltip-series__item-value">{{ model[1]?.series }}: {{ model[1]?.value | currency }}</div>
								</div>
							</div>
						</ng-template>
					</ngx-charts-line-chart>
				</div>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: total-tips">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Total tips
			</div>
		</div>
		<div class="dashboard-card__content tips">
			<div class="tips__current">
				${{ dashboard.totalTips.current }}
			</div>
			<div class="tips__difference">
				{{ dashboard.totalTips.difference }} previous
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: average-tip">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Average tip
			</div>
		</div>
		<div class="dashboard-card__content tips">
			<div class="tips__current">
				${{ dashboard.averageTip.current }}
			</div>
			<div class="tips__difference">
				{{ dashboard.averageTip.difference }} previous
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: occupancy">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Occupancy per Staff Member
			</div>
		</div>
		<div class="dashboard-card__content">
			<app-table [columns]="occupancyColumns" [rows]="dashboard.occupancyRows">
				<ng-template sort cell="staff_name" let-element>
					<div class="client">
						<div class="client__img">
							<picture [user]="element?.staff"></picture>
						</div>
						<div class="client__name">
							{{element?.staff_name}}
						</div>
					</div>
				</ng-template>
				<ng-template sort cell="working_minutes" let-element>
					{{dashboard.formatMinutesToHoursAndMinutes(element?.working_minutes, true)}}
				</ng-template>
				<ng-template sort cell="visit_minutes" let-element>
					{{dashboard.formatMinutesToHoursAndMinutes(element?.visit_minutes, true)}}
				</ng-template>
				<ng-template sort cell="occupancy" let-element>
					<div class="fl-c-c">
						<div class="progress-bar">
							<div class="progress-bar__fill _animation" [ngStyle]="{'width': element?.occupancy + '%'}"></div>
						</div>
						<span>
							{{element?.occupancy ? element?.occupancy : '0'}}%
						</span>
					</div>
				</ng-template>
			</app-table>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: revenue">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">
				Staff Revenue and Tip
			</div>
		</div>
		<div class="dashboard-card__content">
			<app-table [columns]="revenueTipColumns" [rows]="dashboard.revenueTipRows">
				<ng-template sort cell="staff_name" let-element>
					<div class="client">
						<div class="client__img">
							<picture [user]="us._users[element?.staff]"></picture>
						</div>
						<div class="client__name">
							{{ element?.staff_name }}
						</div>
					</div>
				</ng-template>
				<ng-template sort cell="appointments" let-element>
					{{ element?.appointments }}
				</ng-template>
				<ng-template sort cell="revenue" let-element>
					{{ element?.revenue | currency }}
				</ng-template>
				<ng-template sort cell="sum_tips" let-element>
					{{ element?.sum_tips | currency }}
				</ng-template>
				<ng-template sort cell="avg_tip" let-element>
					{{ element?.avg_tip | currency }}
				</ng-template>
			</app-table>
		</div>
	</div>
</div>
