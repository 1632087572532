import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';
import DateHolidays from 'date-holidays';
import { DatePipe } from '@angular/common';
import * as  moment  from 'moment-timezone'

@Injectable({
	providedIn: 'root'
})
export class ClosedService {
	public closeds: any = [];
	public _closeds: any = {};
	constructor(private alert: AlertService, private mongo: MongoService, private datePipe: DatePipe) { 
		this.closeds = mongo.get('closed', {
			replace: {
				start: (val, cb, doc) => { 
					if(doc.holiday) {
						cb(this.getDates(doc).start);
					} else {
						cb(val);
					}
				},
				end: (val, cb, doc) => { 
					if(doc.holiday) {
						cb(this.getDates(doc).end);
					} else {
						cb(val);
					}
				},
				substitute_start: (val, cb, doc) => { 
					if(doc.holiday && doc.substitute) {
						this.getSubstituteDates(doc) ? cb(this.getSubstituteDates(doc).start) : cb(val);
					} else {
						cb(val);
					}
				},
				substitute_end: (val, cb, doc) => { 
					if(doc.holiday && doc.substitute) {
						this.getSubstituteDates(doc) ? cb(this.getSubstituteDates(doc).end) : cb(val);
					} else {
						cb(val);
					}
				},
			}
		}, (arr, obj) => {
			this._closeds = obj;
		});
	}
	create(closed:any, alert:boolean = true, cb: any = () => {}) {
		if(closed._id) return this.update(closed, cb);
		this.mongo.create('closed', closed, (created)=>{
			if(typeof cb === 'function') cb(created);
			if(alert) {
				this.alert.show({
					text: 'Closed Time has been created.'
				});
			}
		});
	}
	update(closed, cb: any = () => {}) {
		this.mongo.afterWhile(closed, ()=> {
			this.mongo.update('closed', closed, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(closed, cb: any = () => {}) {
		this.mongo.delete('closed', closed, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
	getDates(closed: any) {
		const country = closed.holiday.country;
		const state = closed.holiday.state;
		const timezone = closed.holiday.timezone;

		const dateHolidays = new DateHolidays();
		dateHolidays.init(country, state);
		dateHolidays.setTimezone(timezone);

		for(let i = new Date().getFullYear(); i <= new Date().getFullYear() + 10; i++) {
			for(let holiday of dateHolidays.getHolidays(i)) {
				if( holiday.name === closed.holiday.name ) {
					if(new Date(holiday.date) > new Date()) {
						const end = new Date(holiday.end);
						end.setDate(end.getDate() - 1);
						return {
							start: this.dateFormatting(holiday.start, timezone),
							end: this.dateFormatting(end, timezone),
						};
					} else {
						for(let j = i + 1; j <= new Date().getFullYear() + 10; j++) {
							for(let next_holiday of dateHolidays.getHolidays(j)) {
								if( next_holiday.name === closed.holiday.name ) {
									const end = new Date(next_holiday.end);
									end.setDate(end.getDate() - 1);
									return {
										start: this.dateFormatting(next_holiday.start, timezone),
										end: this.dateFormatting(end, timezone),
									};
								}
							}
						}
					}
				}
			}
		}
	}
	getSubstituteDates(closed: any) {
		const date = this.getDates(closed);
		const country = closed.holiday.country;
		const state = closed.holiday.state;
		const timezone = closed.holiday.timezone;

		const dateHolidays = new DateHolidays();
		dateHolidays.init(country, state);
		dateHolidays.setTimezone(timezone);

		for( let holiday of dateHolidays.getHolidays( new Date(date.start?.singleDate?.formatted).getFullYear() ) ) {
			if( holiday.name.indexOf(closed.holiday.name) != -1 && holiday?.substitute ) {
				const end = new Date(holiday.end);
				end.setDate(end.getDate() - 1);
				return {
					start: this.dateFormatting(holiday.start, timezone),
					end: this.dateFormatting(end, timezone),
				};
			}
		}

		return;
	}
	dateFormatting(date: Date, timezone: string) {
		date = new Date(moment(new Date(date)).tz(timezone).format('YYYY-MM-DD HH:mm:ss'));
		return {
			isRange: false,
			singleDate: {
				date: {
					year: date.getFullYear(),
					month: date.getMonth() + 1,
					day: date.getDate()
				},
				jsDate: date,
				formatted: this.datePipe.transform(date, 'M/d/yyyy'),
				epoc: date.getTime()
			},
			dateRange: null
		};
	}
	isHoliday(date: Date, holiday: any, substitute: boolean) {
		const dateHolidays = new DateHolidays();
		dateHolidays.init(holiday.country, holiday.state);
    	// NOT FINISHED
		dateHolidays.setTimezone(moment.tz.guess());
		const holidays = dateHolidays.isHoliday(date);

		if(holidays) {
			for( let h of holidays ) {
				if( h.name === holiday.name ) {
					return true;
				} else if(substitute) {
						if ( h.name.indexOf(holiday.name) != -1 && h.name.indexOf('(substitute day)') != -1 && substitute ) {
						return true;
					}
				}
			}
		}

		return false;
	}
	getHolidays(country: string, state: string, timezone: string = undefined, type: string = undefined): any[] {
		const holidays = [];
		const dateHolidays = new DateHolidays();
	
		switch(country) {
			case 'Canada':
				var shortcode_country = 'CA';	
				break;
			case 'USA':
				var shortcode_country = 'US';	
				break;
		}	

		const states = dateHolidays.getStates(shortcode_country);
		
		var shortcode_state = states ? Object.keys(states).find(shortcode => states[shortcode] === state) : '';

		type ? dateHolidays.init(shortcode_country, shortcode_state, {types: [type]}) : dateHolidays.init(shortcode_country, shortcode_state);
		dateHolidays.setTimezone(timezone);

		for(let i = new Date().getFullYear(); i <= new Date().getFullYear() + 10; i++) {
			dateHolidays.getHolidays(i).forEach(holiday => {
				if( !holidays.find( h => { return h.name.indexOf(holiday.name) != -1 && h.country === shortcode_country } ))  {
					if( !holiday?.substitute ) {
						if(new Date(holiday.date) > new Date()) {
							const end = new Date(holiday.end);
							end.setDate(end.getDate() - 1);
							holidays.push({
								name: holiday.name,
								date: holiday.date,
								type: holiday.type,
								possible_substitute: false,
								possible_repeat: false,
								country: shortcode_country,
								state: shortcode_state,
								start: new Date(moment(new Date(holiday.start)).tz(timezone).format('YYYY-MM-DD HH:mm:ss')),
								end: new Date(moment(new Date(end)).tz(timezone).format('YYYY-MM-DD HH:mm:ss')),
								timezone: timezone
							});
						} else {
							outerLoop: for(let j = i + 1; j <= new Date().getFullYear() + 10; j++) {
								for(let next_holiday of dateHolidays.getHolidays(j)) {
									if( next_holiday.name === holiday.name ) {
										const end = new Date(next_holiday.end);
										end.setDate(end.getDate() - 1);
										holidays.push({
											name: next_holiday.name,
											date: next_holiday.date,
											type: holiday.type,
											possible_substitute: false,
											possible_repeat: false,
											country: shortcode_country,
											state: shortcode_state,
											start: new Date(moment(new Date(next_holiday.start)).tz(timezone).format('YYYY-MM-DD HH:mm:ss')),
											end: new Date(moment(new Date(end)).tz(timezone).format('YYYY-MM-DD HH:mm:ss')),
											timezone: timezone
										});
										break outerLoop;
									}
								}
							}
						}
					} else {
						let name = holiday.name.replace('(substitute day)','').trim();
						if( !holidays.find( h => { return h.name.indexOf(name) != -1 && h.country === shortcode_country } ) ) {
							let main_holiday = dateHolidays.getHolidays(i).find(h => h.name === name);
							if(new Date(main_holiday.date) > new Date()) {
								const end = new Date(main_holiday.end);
								end.setDate(end.getDate() - 1);
								holidays.push({
									name: main_holiday.name,
									date: main_holiday.date,
									type: holiday.type,
									possible_substitute: true,
									possible_repeat: false,
									country: shortcode_country,
									state: shortcode_state,
									start: new Date(moment(new Date(main_holiday.start)).tz(timezone).format('YYYY-MM-DD HH:mm:ss')),
									end: new Date(moment(new Date(end)).tz(timezone).format('YYYY-MM-DD HH:mm:ss')),
									timezone: timezone
								});
							} else {
								outerLoop: for(let j = i + 1; j <= new Date().getFullYear() + 10; j++) {
									for(let next_holiday of dateHolidays.getHolidays(j)) {
										if( next_holiday.name === main_holiday.name ) {
											const end = new Date(next_holiday.end);
											end.setDate(end.getDate() - 1);
											holidays.push({
												name: next_holiday.name,
												date: next_holiday.date,
												type: holiday.type,
												possible_substitute: false,
												possible_repeat: false,
												country: shortcode_country,
												state: shortcode_state,
												start: new Date(moment(new Date(next_holiday.start)).tz(timezone).format('YYYY-MM-DD HH:mm:ss')),
												end: new Date(moment(new Date(end)).tz(timezone).format('YYYY-MM-DD HH:mm:ss')),
												timezone: timezone
											});
											break outerLoop;
										}
									}
								}
							}
						} else {
							holidays.find( h => { return h.name.indexOf(name) != -1 && h.country === shortcode_country } ).possible_substitute = true;
						}
					}
				} else {
					holidays.find( h => { return h.name.indexOf(holiday.name) != -1 && h.country === shortcode_country } ).possible_repeat = true;
				}
			});
		}

		return holidays;
	}
}
