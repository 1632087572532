import { Component, OnInit, Injectable, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { UserService, CheckoutService, LoaderService } from '@services';
import { AlertService, MongoService } from 'wacom';
import { HttpClient } from '@angular/common/http';
import * as es6printJS from "print-js";
import { saveAs } from "file-saver"
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
	changes = new Subject<void>();

	firstPageLabel = `First page`;
	itemsPerPageLabel = `Items per page:`;
	lastPageLabel =`Last page`;
	nextPageLabel = 'Next page';
	previousPageLabel = 'Previous page';

	getRangeLabel(page: number, pageSize: number, length: number): string {
		if (length === 0) {
		return `Page 1 of 1`;
		}
		const amountPages = Math.ceil(length / pageSize);
		return `Page ${page + 1} of ${amountPages}`;
	}
}

@Component({
	selector: 'app-checkouts',
	templateUrl: './checkouts.component.html',
	styleUrls: ['./checkouts.component.scss'],
  	providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})
export class CheckoutsComponent implements OnInit, AfterViewInit, OnDestroy {
	public search: any = '';
	public sort_type:any = {};
	public searching;
	public day;
	public type;
	public config: any = {
		page: 1,
		perPage: 100
	}
	
	constructor(
		public us: UserService, 
		public cs: CheckoutService, 
		private http: HttpClient, 
		private alert: AlertService,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef
	) {
		this.us.headerTitle = 'Sales';
		this.us.headerSubtitle = 'Admin';
	}

	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));

		this.sort_type = {
			title: 'creation_date.singleDate.jsDate',
			direction: 'desc'
		}

		this.refreshChecks();

		if(window.innerWidth <= 1024 && window.innerWidth > 767 && this.config.perPage != 50) this.config.perPage = 50
		if(window.innerWidth <= 767 && this.config.perPage != 25) return this.config.perPage = 25
	}
	ngAfterViewInit() {
		this.mongo.on('user', () => {
			this.cs.loaded(() => {
				this.loader.remove();
			});
		});
	}
	ngOnDestroy() {
		this.cs.clear();
	}

	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
		if(!this.sort_type.direction) this.sort_type.title = 'creation_date.singleDate.jsDate';
		
		this.refreshChecks();
	}
	printInvoice(check: any) {
		if(typeof window.print != 'function') {
			return this.alert.show({
				type: 'error',
				text: 'Printing is not supported on this device'
			});
		}
		this.http.get('/api/invoice/file/'+check._id, { responseType: 'blob'}).subscribe(blob => {
			blob.text().then(value => {
				if(value != 'false') es6printJS(URL.createObjectURL(blob), 'pdf');
				else this.alert.show({
					type: 'error',
					text: 'Invoice not found.'
				});
			});
		});
	}
	downloadInvoice(check: any) {
		this.http.get('/api/invoice/file/'+check._id, { responseType: 'blob'}).subscribe(blob => {
			blob.text().then(value => {
				if(value != 'false') {
					this.http.get('/api/invoice/name/'+check._id, { responseType: 'text'}).subscribe(name => {
						saveAs(blob, name);
					});
				}
				else this.alert.show({
					type: 'error',
					text: 'Invoice not found.'
				});
			});
		});
	}

	handlePageEvent(event) {
		this.config.page = event.pageIndex + 1;
		this.refreshChecks();
	}

	refreshChecks() {
		if(!this.loader.isLoaderShowing) this.loader.show({transparent: true, preventClick: true}, this.eref.nativeElement.querySelector('.table-container'));
		this.cs.getChecksPagination(this.config, this.search, this.day ? this.day?.singleDate?.formatted : null, this.type, this.sort_type, () => {
			this.loader.remove();
		});
	}
}