import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { UserService, AppointmentService, ServiceService, NoteService, ReasonService, LocationService, ClosedService, LoaderService } from '@services';
import * as  moment  from 'moment-timezone'
import { AlertService, ModalService, MongoService } from 'wacom';
import { flyAnimation } from 'src/app/common/animation/animation.component';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { fromEvent } from 'rxjs';
@Component({
	selector: 'appointment',
	templateUrl: './appointment.component.html',
	styleUrls: ['./appointment.component.scss'],
	animations: [flyAnimation]
})
export class AppointmentComponent implements OnInit {
	public close:any;
	public noteEditor: any;
	public appointHistory:any;
	public moreHistory:any = false;
	public status:string;
	public start:string;
	public day:string;
	public profile:any={};
	public clients: any = [];
	public allowed_appointments: any = [];
	public locations: any = [];
	public minDate: Date;
	public errorDay: string = '';
	public characterLimit: string = null;
	constructor(public aps: AppointmentService,
		public ss: ServiceService,
		public us: UserService,
		public nt: NoteService,
		public rs: ReasonService,
		public loc: LocationService,
		public modal: ModalService,
		public alert: AlertService,
		private mongo: MongoService,
		private cs: ClosedService,
		private loader: LoaderService) {}
	ngOnInit(): void {
		this.mongo.on('location', () => {
			if (!this.selectStatuses.find(s => s == this.profile.status)) this.selectStatuses.push(this.profile.status);

			const apptTime = new Date(this.profile.day.singleDate.jsDate);
			apptTime.setHours(this.profile.start.split(':')[0], this.profile.start.split(':')[1], 0);
			
			if (this.profile.location) {
				if(moment(apptTime.toISOString()).tz(this.loc._locations[this.profile.location]?.timezone, true).diff(moment().tz(this.loc._locations[this.profile.location]?.timezone), 'd') < 0) {
					this.profile.expired = true;
				}
				
				if(moment(apptTime).tz(this.loc._locations[this.profile.location]?.timezone, true).diff(moment().tz(this.loc._locations[this.profile.location]?.timezone), 'm') <= 0) {
					this.selectStatuses.push('No-show');
				}

				this.minDate = new Date( moment().tz( this.loc._locations[this.profile.location]?.timezone ).format() );
			} else {
				if(moment(apptTime.toISOString()).diff(moment(), 'days') < 0) {
					this.profile.expired = true;
				}

				if(moment(apptTime).diff(moment(), 'm') <= 0) {
					this.selectStatuses.push('No-show');
				}

				this.minDate = new Date();
			}
		});
		if (this.profile.notes) this.noteEditor = JSON.parse(this.profile.notes);
		this.status = this.profile.status;
		this.start = this.profile.start;
		this.day = this.profile.day.singleDate.formatted;
		this.mongo.on('user location closed', () => {
			this.clients = this.clients.concat(this.us.clients);
			if(this.profile.client && this.us._users[this.profile.client]?.deleted && !this.clients.find((c) => {return c._id == this.profile.client})) this.clients.unshift(this.us._users[this.profile.client]);

			this.allowedRefresh();
			if(this.profile.user && this.us._users[this.profile.user]?.deleted && !this.allowed_appointments.find((c) => {return c._id == this.profile.user})) this.allowed_appointments.unshift(this.us._users[this.profile.user]);

			this.locationsRefresh();
		});
		this.onClick().subscribe(event => {
			if(!document.querySelector('.add-client').contains(event.target as Node)) this.add_popup = false;
		});
	}
	public selectStatuses = ['Canceled', 'New', 'Completed', 'Confirmed'];
	public kinds = [{name: 'Daily'}, {name: 'Weekly'}, {name: 'Monthly'}, {name: 'Yearly'}];
	public repeat:any = {
		kind: this.kinds[0],
		count: 1
	};
	public note = '';
	public submitted = false;
	update_duration(){
		try{
			this.profile.duration = this.ss._services[this.profile.service].time || 0;
		}catch(err){
			this.profile.duration = 0;
		}
	}
	submit(){
		this.submitted = true;
		if(!this.profile.service||!this.profile.user||!this.profile.start||!this.profile.duration||!this.profile.day||!this.profile.client||this.profile.status=='Canceled'&&!this.profile.reason||!this.profile.location||this.repeat.enable&&(!this.repeat.kind||!this.repeat.count)) return this.alert.error({
			text: "Please fill required fields",
		});
		if(!this.dayValidation(this.profile.day)) return this.alert.error({
			text: "Please select a different day",
		});
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		if(!this.profile._id&&this.repeat.enable) this.profile.repeat = true;
		if(this.noteEditor) this.profile.notes = JSON.stringify(this.noteEditor);
		if(this.profile.status === 'No-show') this.profile.price = this.ss._services[this.profile.service].price;
		this.aps.create(this.profile, (appointment)=> {
			this.submitted = false;
			this.aps.rebook(appointment, this.repeat, false);
			if (this.start != this.profile.start || this.day != this.profile.day.singleDate.formatted) {
				this.aps.reschedule(this.profile);
			}
			if (this.status != this.profile.status && this.profile.status == 'Canceled') {
				this.aps.cancel(this.profile);
			}
			this.loader.remove();
			this.close();
		}, true, () => {
			this.submitted = false;
			this.loader.remove();
		});
	}
	addNote(obj) {
		if(this.note) {
			this.nt.create({description: this.note, staff: obj.user, appointment: obj._id,client: this.us._id});
			this.note = ''
		}
	}
	delete(profile) {
		this.aps.delete(profile, ()=> {
			this.loader.remove();
			this.close();
		}, () => {
			this.loader.show({ 
				modal: true,
				transparent: true,
				preventClick: true
			}, document.querySelector('.modal-box'));
		}); 	
	}
	locationsRefresh() {
		if(this.profile.user && this.us._users[this.profile.user]?.location?.length) {
			this.locations = this.us._users[this.profile.user].location.map((l) => {
				const location = this.loc._locations[l];
				outerLoop: for (let i = this.cs.closeds.length-1; i >= 0; i--){
					for (let j = 0; j < this.cs.closeds[i].locations.length; j++) {
						if(
							this.cs.closeds[i].locations[j] == l && 
							(
								(
									!this.cs.closeds[i].holiday &&
									new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(this.profile.day?.singleDate?.formatted) && 
									new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(this.profile.day?.singleDate?.formatted)
								) ||
								(
									this.cs.closeds[i].holiday &&
									this.cs.isHoliday(new Date(this.profile.day?.singleDate?.formatted), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
								)
							)
						) {
							location.disabled = true;
							break outerLoop;
						} else {
							location.disabled = false;
						}
					}
				}
				return location;
			});
		}
	}
	locationChange() {
		this.locationsRefresh();

		if (this.profile.location) {
			if(moment(new Date(this.profile.day.singleDate.jsDate)).tz(this.loc._locations[this.profile.location]?.timezone, true).diff(moment().tz(this.loc._locations[this.profile.location]?.timezone), 'd') < 0) {
				this.profile.expired = true;
			}

			this.minDate = new Date( moment().tz( this.loc._locations[this.profile.location]?.timezone ).format() );
		} else {
			if(moment(new Date(this.profile.day.singleDate.jsDate).toISOString()).diff(moment(), 'days') < 0) {
				this.profile.expired = true;
			}

			this.minDate = new Date();
		}
	}
	public new_client: any = {data: {}};
	public new_client_submitted: boolean = false;
	public add_popup = false;
	public left_popup = false;
	openAddPopup() {
		this.add_popup = !this.add_popup;
		if (document.getElementsByClassName('modal-box')[0].getBoundingClientRect().left > 300) this.left_popup = true;
		else this.left_popup = false;
	}
	@HostListener('window:resize')
	onResize() {
		if (document.getElementsByClassName('modal-box')[0].getBoundingClientRect().left > 300) this.left_popup = true;
		else this.left_popup = false;
	}
	@HostBinding('document:click') 
	onClick() {
		return fromEvent(document.querySelector('.modal-content'), 'click');
	}
	addClient() {
		if (!this.profile.expired) {
			this.new_client_submitted = true;
			if(!this.new_client.firstName||!this.new_client.lastName||!this.new_client.email) return this.alert.error({
				text: "Please fill required fields",
			});
			this.us.create(this.new_client, (created) => {
				this.us.getUsers();
				this.clients = this.us.clients;
				this.new_client_submitted = false;
				this.add_popup = false;
				this.new_client = {data: {}};
				this.profile.client = created._id;
			});
		}
	}
	dayValidation(event) {
		if(event) {
			this.minDate.setHours(0, 0, 0, 0);
			if(new Date(event?.singleDate?.formatted) < this.minDate) {
				this.errorDay = 'The chosen day has passed';
				return false;
			} else this.errorDay = '';
			if(!this.dateFilter(new Date(event?.singleDate?.formatted))) {
				this.errorDay = 'Closed on selected day';
				return false;
			} else this.errorDay = '';
		} else this.errorDay = '';
		return true;
	}
	dateFilter = (d: Date): boolean => {
		for (let i = this.cs.closeds.length-1; i >= 0; i--){
			for (let j = 0; j < this.cs.closeds[i].locations.length; j++) {
				if(
					this.profile.location && this.cs.closeds[i].locations[j] == this.profile.location && 
					(
						(
							!this.cs.closeds[i].holiday &&
							new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
							new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(d)
						) ||
						(
							this.cs.closeds[i].holiday &&
							this.cs.isHoliday(new Date(d), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
						)
					)
				) {
					return false;
				}
			}
		}
        return true;
    }
	allowedRefresh() {
		this.allowed_appointments = [];
		this.allowed_appointments = this.allowed_appointments.concat(this.us.allowed_appointments.map(u => {
			let disabled = 0;
			for(let location of u.location) {
				for (let i = this.cs.closeds.length-1; i >= 0; i--){
					for (let j = 0; j < this.cs.closeds[i].locations.length; j++) {
						if(
							this.cs.closeds[i].locations[j] == location && 
							(
								(
									!this.cs.closeds[i].holiday &&
									new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(this.profile.day?.singleDate?.formatted) && 
									new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(this.profile.day?.singleDate?.formatted)
								) ||
								(
									this.cs.closeds[i].holiday &&
									this.cs.isHoliday(new Date(this.profile.day?.singleDate?.formatted), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
								)
							)
						) {
							disabled++;
						}
					}
				}
			}
			u.disabled = disabled === u.location?.length;
			return u;
		}));
	}
}
